import ApplicationController from './application_controller'
import $, { event } from 'jquery';
import Select2 from "select2"
/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {


  connect () {
    super.connect()
    // add your code here, if applicable
    console.log("change_event");

    $('.select2').select2();
    $(".select2-events").on('select2:select', e => {
        console.log("choose_event");
        this.choose_event(e)
    })
    $(".select2-customers").on('select2:select', e => {
      console.log("choose_customer");
      this.choose_customer(e)
    })

  }

  change_event(e) {
    console.log('change_event 2')
    const event_id = e.target.value
    this.stimulate("notes#change_event", event_id)
  }

  
  choose_customer(e) { 
    this.stimulate("notes#choose_customer2", e.target.value)
    }
    choose_event(e) { 
      this.stimulate("notes#choose_event2", e.target.value)
      }

}
