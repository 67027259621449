import * as langs from "./langs";
import { HeaderMenu } from "./tabulator-extensions";

class tablatorColumn {
  fieldId;
  field;
  position = 0;
  dataType = "string";
  customProperties = {
    visible: true,
    sorter: "string",
    headerFilter: true,
    model: "",
    enumTypes: [],
  };

  constructor(
    fieldId,
    field,
    dataType = "string",
    position = null,
    customProperties = {}
  ) {
    this.fieldId = fieldId;
    this.field = field;
    this.position = position;
    this.dataType = dataType;
    this.setAndRemovePosition(customProperties);
    if (!customProperties.title) customProperties.title = getTitle(field);
    this.customProperties = {
      width: null,
      visible: this.setVisibility(fieldId),
      toRemove: !this.setVisibility(fieldId),
      sorter: "string",
      headerFilter: true,
      enumTypes: [],
      headerMenu: HeaderMenu,
      isEditable: false,
    };
    this.setColumnType(customProperties.dataType, customProperties.enumTypes);
    this.customProperties = { ...this.customProperties, ...customProperties };
  }

  setCustomProperies(props = {}) {
    this.setAndRemovePosition(props);
    if (props.dataType && props.dataType != this.dataType) {
      props = { ...props, ...getProps(props.dataType, []) };
    }
    this.customProperties = { ...this.customProperties, ...props };
  }

  setColumnType(type, enumTypes = []) {
    const props = getProps(type, enumTypes);
    this.customProperties = { ...this.customProperties, ...props };
  }

  setVisibility(field) {
    let show = true;
    if (field.includes("_at")) {
      show = false;
    }
    if (field.includes("id")) {
      show = false;
    }
    return show;
  }

  setAndRemovePosition(props) {
    if (props.hasOwnProperty("position")) {
      this.position = props.position;
      delete props.position;
    }
  }

  getColumn() {
    const col = {
      field: this.field,
      model: this.model,
      dataType: this.dataType,
      ...this.customProperties,
    };
    delete col.toRemove;
    return col;
  }
}

class tablatorColumnsList {
  columns = [];
  visibleColumns = 0;

  constructor() {
    this.columns = [];
  }

  addColumn(col) {
    this.columns.push(col);
    if (col.customProperties.visible)
      this.visibleColumns = this.visibleColumns + 1;
  }

  getColumns(config) {
    this.orderColumns();
    this.limitColumnsVisibilty(config.limit_columns);
    const arr = [];
    this.columns.forEach((col) => {
      if (!col.customProperties.toRemove) arr.push(col.getColumn());
    });
    return arr;
  }

  orderColumns() {
    const arr = [];
    for (var i = 0; i < this.columns.length; i++) {
      arr.push(null);
    }
    this.columns.forEach((col) => {
      if (col.position) arr[col.position] = col;
    });
    this.columns.forEach((col) => {
      if (!col.position) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] == null) {
            arr[i] = col;
            break;
          }
        }
      }
    });
    this.columns = arr;
  }

  limitColumnsVisibilty(limit = 7) {
    let i = 0;
    this.columns.forEach((col) => {
      if (col.customProperties.visible) i++;
      if (i > limit) col.setCustomProperies({ visible: false });
    });
  }
}

export function getColumns(columns = {}, customColumns = [], config = {}) {
  const tablatorColumns = new tablatorColumnsList();
  for (let key in columns) {
    const customColumn =
      customColumns.filter((col) => col.field == key)[0] || {};
    customColumns = customColumns.filter((col) => col.field != key);
    const column = columns[key];
    if (!customColumn.toRemove) {
      const test = new tablatorColumn(
        key,
        column.field,
        column.dataType,
        null,
        column
      );
      test.setCustomProperies(customColumn);
      tablatorColumns.addColumn(test);
    }
  }

  customColumns.forEach((col) => {
    if (!col.toRemove) {
      const test = new tablatorColumn(col.field, col.field, null, null, col);
      tablatorColumns.addColumn(test);
    }
  });

  return tablatorColumns.getColumns(config);
}

function getTitle(field) {
  const names = langs.getLang().heb.columns;
  return names[field] || field;
}

function getProps(type, enumKinds = []) {
  const types = {
    money: {
      sorter: "number",
      bottomCalc: "sum",
      bottomCalcParams: {
        precision: 1,
      },
      formatter: "money",
      formatterParams: {
        decimal: ".",
        thousand: ",",
        symbol: "₪",
        symbolAfter: "₪",
        precision: false,
      },
    },
    enum: {
      headerFilterPlaceholder: " ",
      headerFilter: "list",
      headerFilterFunc: "in",
      headerFilterParams: {
        values: enumKinds,
        clearable: true,
        multiselect: true,
      },
    },
    eventStatus: {
      mutator: "statusMutator",
      headerFilterPlaceholder: " ",
      headerFilter: "list",
      headerFilterFunc: "in",
      headerFilterParams: {
        valuesLookup: "active",
        valuesLookupField: "status",
        sort: "asc",
        multiselect: true,
      },
      formatter: "statusFormatter",
      accessorDownload: "htmlAccessor",
      formatterPrint: "printFormatter",
    },
    vacancyStatus: {
      mutator: "vacancyMutator",
      headerFilterPlaceholder: " ",
      headerFilter: "list",
      headerFilterFunc: "in",
      headerFilterParams: {
        valuesLookup: "active",
        sort: "asc",
        multiselect: true,
      },
      formatter: "statusFormatter",
      accessorDownload: "htmlAccessor",
      formatterPrint: "printFormatter",
    },
    sheets: {
      mutator: "vacancyNotesMutator",
      formatter: "yesNoVacancyFormatter",

      mutatorParams: "sheets",
    },
    towels: {
      mutator: "vacancyNotesMutator",
      formatter: "yesNoVacancyFormatter",
      mutatorParams: "towels",
    },
    notes: {
      mutator: "vacancyNotesMutator",
      mutatorParams: "notes",
    },
    number: {
      mutator: "vacancyNotesMutator",
      mutatorParams: "number",
      editor: "number",
      editable: false,
      editorParams: {
        min: 0,
        max: 10,
        step: 1,
      },
    },
    boolean: {
      headerFilter: "tickCross",
      width: 40,
      headerMenu: false,
    },

    spaceLink: {
      bottomCalc: "count",
      accessorDownload: "htmlAccessor",
      formatterPrint: "printFormatter",
    },
    date: {
      mutator: "dateMutator",
      headerFilter: "minMaxFilterEditor",
      accessorDownload: "dateAccessor",
      headerFilterFunc: "minMaxFilterFunction",
      headerFilterLiveFilter: false,
    },
  };
  if (types.hasOwnProperty(type)) return types[type];
  return {};
}
