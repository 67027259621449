import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["tableWrapper", "table", "tableBar", "colToFilter"];
  static values = ["resizeObserver"];
  connect() {
    super.connect();
    this.resizeObserverValue = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          this.showAllCols();
          this.setColsVisibility(entry.contentRect.width);
          this.toggleBarBtn();
        }
      }
    });
    this.resizeObserverValue.observe(this.tableTarget.parentElement);
    const trs = this.tableTarget.querySelectorAll("tr");
    const cols = trs[0].querySelectorAll("th");
    this.colToFilterTarget.innerHTML = "";
    const opt = document.createElement("option");
    opt.value = -1;
    opt.innerHTML = "הכל";
    opt.selected = true;
    this.colToFilterTarget.appendChild(opt);

    cols.forEach((col, i) => {
      const opt = document.createElement("option");
      opt.value = i;
      opt.innerHTML = col.textContent;
      this.colToFilterTarget.appendChild(opt);
      col.addEventListener("click", (e) => {
        if (e.target.tagName == "TH") this.onColumnHeaderClicked(e);
      });
    });
  }
  addColToggleCheckbox() {
    const trs = this.tableTarget.querySelectorAll(
      "tr:not(.table__row-noItems)"
    );
    const cols = trs[0].querySelectorAll("th");
    console.log(cols);
    const div = this.tableWrapperTarget.querySelector(".js-colList");
    if (div) {
      div.innerHTML = "";
      cols.forEach((col, i) => {
        div.appendChild(
          this.createCheckbox(
            col.textContent,
            i,
            !col.classList.contains("hide")
          )
        );
      });
    }
  }

  createCheckbox(header, i, checked) {
    var wrapper = document.createElement("div");

    // creating checkbox element
    var checkbox = document.createElement("input");

    // Assigning the attributes
    // to created checkbox
    const id = Date.now() + "-id-" + i;
    checkbox.type = "checkbox";
    checkbox.name = header;
    checkbox.value = i;
    checkbox.id = id;
    checkbox.checked = checked;
    checkbox.addEventListener("change", () => this.toggleColNum(i));

    // creating label for checkbox
    var label = document.createElement("label");

    // assigning attributes for
    // the created label tag
    label.htmlFor = id;

    // appending the created text to
    // the created label tag
    label.appendChild(document.createTextNode(header));

    // appending the checkbox
    // and label to div
    wrapper.appendChild(checkbox);
    wrapper.appendChild(label);
    wrapper.style.display = "flex";
    return wrapper;
  }
  toggleFullscreen() {
    this.tableWrapperTarget.classList.toggle("modal-fullscreen");
  }

  toggleColNum = function (num) {
    const trs = this.tableTarget.querySelectorAll(
      "tr:not(.table__row-noItems)"
    );

    trs.forEach((tr) => {
      const tds = tr.querySelectorAll("td , th");
      tds[num].classList.toggle("hide");
    });
  };

  setColsVisibility(wrapperWidth) {
    if (this.isOverflown(this.tableTarget.parentElement)) {
      this.hideCol();
      this.setColsVisibility(wrapperWidth);
    }
    this.addColToggleCheckbox();
  }

  hideCol() {
    const trs = this.tableTarget.querySelectorAll(
      "tr:not(.table__row-noItems)"
    );
    const colNum = trs[0].querySelectorAll("th:not(.hide)").length;

    trs.forEach((tr) => {
      const tds = tr.querySelectorAll("td , th");
      tds[colNum - 1].classList.add("hide");
    });
  }
  showAllCols() {
    const tds = this.tableTarget.querySelectorAll("td , th");
    tds.forEach((td) => {
      td.classList.remove("hide");
    });
  }

  toggleBarBtn() {
    if (this.tableBarTarget.clientWidth < 300)
      this.tableBarTarget.classList.add("small");
    else this.tableBarTarget.classList.remove("small");
  }

  isOverflown(element) {
    return element.scrollWidth > element.clientWidth;
  }

  tableToCSV(e) {
    e.preventDefault();
    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = this.tableTarget.querySelectorAll("tr");
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(this.checkForText(cols[j]));
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // add UTF-8 BOM to beginning so excel doesn't get confused.
    const BOM = String.fromCharCode(0xfeff);
    csv_data = BOM + csv_data;
    // Call this function to download csv file
    this.downloadCSVFile(csv_data);
  }

  downloadCSVFile(csv_data) {
    // Create CSV file object and feed
    // our csv_data into it
    const CSVFile = new Blob([csv_data], {
      type: "text/csv;charset=UTF-8",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = "GfG.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  search(e) {
    // Declare variables
    var input, filter, table, tr, td, i, n, txtValue, col;
    input = e.target;
    filter = input.value.toUpperCase();
    table = this.tableTarget;
    const rows = Array.from(
      table.querySelectorAll(":scope > tbody > tr:not(.table__row-noItems)")
    );
    tr = table.getElementsByTagName("tr");
    col = this.colToFilterTarget.value;
    rows.forEach((row) => {
      row.style.display = "none";
    });

    const filteredRows = rows.filter((row) => {
      txtValue = "";
      const cellsTextArr = Array.from(row.cells).map((cell) =>
        this.checkForText(cell)
      );
      console.log(cellsTextArr);

      if (col == -1) {
        txtValue = cellsTextArr.join(" ");
        console.log(txtValue);
      } else {
        txtValue = cellsTextArr[col];
      }
      return txtValue == "" || txtValue.toUpperCase().indexOf(filter) > -1;
    });
    filteredRows.forEach((row) => {
      row.style.display = "";
    });

    for (let row of rows) {
      table.tBodies[0].appendChild(row);
    }
  }

  checkForText(td) {
    if (!td || td.className == "td-actions") return "";
    console.log(td.dataset);

    if (td.dataset && td.dataset.text) return td.dataset.text;
    if (td.querySelector("input[type=checkbox]"))
      return td.querySelector("input[type=checkbox]").checked ? "1" : "0";
    if (td.querySelector("input")) return td.querySelector("input").value;
    if (td.querySelector("textarea")) return td.querySelector("textarea").value;
    if (td.querySelector("select"))
      return td.querySelector("select").options[
        td.querySelector("select").selectedIndex
      ].text;
    return td.innerText;
  }

  // This code has TypeScript type annotations, but can be used directly as pure JavaScript by just removing the type annotations first.

  sortTableRowsByColumn(table, columnIndex, ascending) {
    const rows = Array.from(
      table.querySelectorAll(":scope > tbody > tr:not(.table__row-noItems)")
    );

    rows.sort((x, y) => {
      const xValue = this.checkForText(x.cells[columnIndex]);
      const yValue = this.checkForText(y.cells[columnIndex]);

      const xNum = parseFloat(xValue);
      const yNum = parseFloat(yValue);

      if (isNaN(xNum) || isNaN(yNum)) {
        return ascending
          ? xValue.localeCompare(yValue)
          : yValue.localeCompare(xValue);
        // <-- Neat comparison trick.
      }
      return ascending ? yNum - xNum : xNum - yNum; // <-- Neat comparison trick.
    });

    // There is no need to remove the rows prior to adding them in-order because `.appendChild` will relocate existing nodes.
    for (let row of rows) {
      table.tBodies[0].appendChild(row);
    }
  }

  onColumnHeaderClicked(ev) {
    const th = ev.currentTarget;
    const table = th.closest("table");
    const thIndex = Array.from(th.parentElement.children).indexOf(th);

    const ascending = th.dataset.sort != "asc";

    this.sortTableRowsByColumn(table, thIndex, ascending);
    const allTh = table.querySelectorAll(":scope > thead > tr > th");
    for (let th2 of allTh) {
      delete th2.dataset["sort"];
      th2.classList.remove("asc");
      th2.classList.remove("desc");
    }
    th.dataset.sort = ascending ? "asc" : "desc";
    th.classList.toggle("asc", ascending);
    th.classList.toggle("desc", !ascending);
  }
}

/*
sortTable(n, cols) {
    cols.forEach(col => {
        col.classList.remove('asc')
        col.classList.remove('desc')

    })
    var table, rows, switching, i, x, y,textX, textY, shouldSwitch, dir, switchcount = 0;
    table = this.tableTarget;
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
/*
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
/*
      let rowStart = this.isSortable(rows[1])  ? 1 : 2
      for (i = rowStart; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;

        
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
/*
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        textX = this.checkForText(x)
        textY = this.checkForText(y)
        
        console.log(textX)
        console.log(textY)
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
/*
        if (dir == "asc") {

          if (textX.toLowerCase() > textY.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true ;
            break;
          }
        } else if (dir == "desc") {

          if (textX.toLowerCase() < textY.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
/*
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
/*
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
      cols[n].classList.add(dir)

    }
  }
*/
