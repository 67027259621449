import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "equipmentTemplate",
    "roomTemplate",
    "roomOrderTemplate",
    "mealTemplate",
    "noteTemplate",
    "classroomTemplate",
    "addRoomOrders",
    "addMeals",
    "addNotes",
    "mealDays",
    "startDate",
    "endDate",
    "addRooms",
    "addClassrooms",
    "addEquipments",
    "cloneBtns",
  ];

  get eventsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("#event-form"),
      "events"
    );
  }
  get formController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("#event-form"),
      "layouts--form"
    );
  }

  connect() {
    super.connect();
    this.set_space();
    let clone_shibutz, index;
    if (this.hasCloneBtnsTarget && false) {
      this.addRoomOrdersTarget.querySelectorAll("tr").forEach((tr, i) => {
        index = tr.dataset.index;
        clone_shibutz = `<button class='btn' type=button value="room-order-${index}" data-action="order#clone_from_order">שכפול הזמנה ${
          i + 1
        }</button>`;
        this.cloneBtnsTarget.insertAdjacentHTML("beforeend", clone_shibutz);
      });
    }
  }
  add_room_order(e) {
    const time = new Date().getTime();
    const content = this.roomOrderTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      time
    );
    const start_date = document.querySelector("#event_start_date").value;
    const end_date = document.querySelector("#event_end_date").value;
    const night_amount = Math.round(
      (new Date(end_date) - new Date(start_date)) / (1000 * 60 * 60 * 24)
    );
    this.addRoomOrdersTarget.insertAdjacentHTML("beforeend", content);
    const participant_num = document.querySelector(
      "#event_participant_num"
    ).value;
    let towels = parseInt(
      document.querySelector("#customer-info-towels").innerHTML
    );
    let sheets = parseInt(
      document.querySelector("#customer-info-sheets").innerHTML
    );
    document.querySelector(
      `#event_room_orders_attributes_${time}_towels`
    ).checked = towels;
    document.querySelector(
      `#event_room_orders_attributes_${time}_sheets`
    ).checked = sheets;
    document.querySelector(
      `#event_room_orders_attributes_${time}_participant_num`
    ).value = participant_num;
    document.querySelector(
      `#event_room_orders_attributes_${time}_start_date`
    ).value = start_date;
    document.querySelector(
      `#event_room_orders_attributes_${time}_end_date`
    ).value = end_date;
    document.querySelector(
      `#event_room_orders_attributes_${time}_night_amount`
    ).value = night_amount;
    document.querySelector(`#night-amount-display-${time}`).innerHTML =
      night_amount;
    const list_num = this.addRoomOrdersTarget.querySelectorAll("tr").length;
    document.querySelector(`#room-order-list-${time}`).innerHTML = list_num;
    const clone_shibutz = `<button class='btn toolbar' type=button value="room-order-${time}" data-action="order#clone_from_order">הוספה לפי הזמנה  ${list_num}</button>`;
    document
      .querySelector(".clone-shibutz-btns")
      .insertAdjacentHTML("beforeend", clone_shibutz);
    this.eventsController.set_event_final_price();
    this.set_dates(e);
    this.formController.updateInputSize();
  }
  remove_room_order(e) {
    let wrapper = e.target.closest(".nested-rooms");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
    this.eventsController.set_event_final_price();
  }

  add_meal(e, type = null, date = null, participant = null, data = null) {
    const time = new Date().getTime();
    var content = this.mealTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      time
    );
    this.addMealsTarget.insertAdjacentHTML("beforeend", content);
    const participant_num = participant
      ? participant
      : document.querySelector("#event_participant_num").value;
    const start_date = date
      ? date
      : document.querySelector("#event_start_date").value;
    document.querySelector(`#event_meals_attributes_${time}_day`).value =
      start_date;
    document.querySelector(
      `#event_meals_attributes_${time}_participant_num`
    ).value = participant_num;
    const kindEl = document.querySelector(
      `#event_meals_attributes_${time}_kind`
    );
    if (type !== null) {
      kindEl.value = type;
      this.eventsController.set_meal_unit_price(kindEl, false);
    }
    if (data) {
      document.querySelector(`#event_meals_attributes_${time}_time`).value =
        data.time[type];
      document.querySelector(
        `#event_meals_attributes_${time}_vegan_num`
      ).value = data.vegan.num;
      document.querySelector(
        `#event_meals_attributes_${time}_vegan_notes`
      ).value = data.vegan.notes;
      document.querySelector(
        `#event_meals_attributes_${time}_veggy_num`
      ).value = data.veggy.num;
      document.querySelector(
        `#event_meals_attributes_${time}_veggy_notes`
      ).value = data.veggy.notes;
      document.querySelector(`#event_meals_attributes_${time}_alergies`).value =
        data.alergies;
    }

    this.eventsController.set_event_final_price();
    this.set_dates(e);
  }
  remove_meal(e) {
    console.log("remove meal");
    let wrapper = e.target.closest(".nested-meals");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = true;
      wrapper.style.display = "none";
    }
    this.eventsController.set_event_final_price();
  }
  add_note(e, type = null, date = null, participant = null, data = null) {
    const time = new Date().getTime();
    const kind = e.target.dataset.kind;

    var content = this.noteTemplateTarget.innerHTML
      .replace(/NEW_RECORD/g, time)
      .replace(/KIND/g, kind);
    this.addNotesTarget.insertAdjacentHTML("beforeend", content);
    document.querySelector(`#event_notes_attributes_${time}_kind`).value = kind;
    document.querySelector(
      `#event_notes_attributes_${time}_kindHead`
    ).textContent = e.target.dataset.heb;
    document.querySelector(
      `#event_notes_attributes_${time}_kindHead`
    ).className = kind + "-bg kind-head";
    document.querySelector(
      `#event_notes_attributes_${time}_title_select`
    ).style = "display:none;";

    if (kind == "feedback") {
      document.querySelector(
        `#event_notes_attributes_${time}_title_select`
      ).style = "display:block;";
      document.querySelector(`#event_notes_attributes_${time}_title`).style =
        "display:none;";

      document
        .querySelector(`#event_notes_attributes_${time}_sum`)
        .setAttribute("min", 0);
      document
        .querySelector(`#event_notes_attributes_${time}_sum`)
        .setAttribute("max", 10);
    }
    console.log(kind);
    console.log(this.noteTemplateTarget);
  }
  remove_note(e) {
    let wrapper = e.target.closest(".nested-notes");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }

  select_note_title(e) {
    let wrapper = e.target.closest(".nested-notes");
    wrapper.querySelector(".title-input").value = e.target.value;
  }
  update_picker(e) {
    const target = e.target;
    const inputKind = e.target.dataset.kind;
    const picker = target.closest(".spacePicker");
    const pickerWrapper = picker.parentElement;
    const tr = target.closest("tr");
    const index = tr ? tr.dataset.index : "new";
    const picker_start_date =
      inputKind == "start" ? e.target.value : picker.dataset.start;
    const picker_end_date =
      inputKind == "end" ? e.target.value : picker.dataset.end;
    const picker_kind = picker.dataset.kind;
    const eventId = picker.dataset.event_id;
    console.log("eventId->", target.dataset);
    this.stimulate(
      "room_order#update_space_picker",
      picker_start_date,
      picker_end_date,
      picker_kind,
      index,
      "",
      eventId
    ).then(() => {
      pickerWrapper.querySelector(".spacePicker").classList.remove("hide");
      this.check_choosen_spaces(pickerWrapper);
    });
  }
  open_picker(e) {
    const target =
      e.target.nodeName === "BUTTON" ? e.target : e.target.closest("button");
    const picker = target.closest(".shibutz").querySelector(".spacePicker");
    const tr = target.closest("tr");
    const index = tr ? tr.dataset.index : "new";
    const space = tr
      ? document.querySelector(`#space-select-${index}`).value
      : "";
    const spaceName = tr
      ? document.querySelector(`#space-select-${index}-label`).value
      : "";
    const btn_start_date = tr
      ? document.querySelector(`#event_chunks_attributes_${index}_start_date`)
          .value
      : document.querySelector("#event_start_date").value;
    const btn_end_date = tr
      ? document.querySelector(`#event_chunks_attributes_${index}_end_date`)
          .value
      : document.querySelector("#event_end_date").value;
    const btn_kind = target.dataset.kind;
    const eventId = target.dataset.event_id;
    console.log("eventId->", target.dataset);
    this.stimulate(
      "room_order#update_space_picker",
      btn_start_date,
      btn_end_date,
      btn_kind,
      index,
      space,
      eventId
    ).then(() => {
      target
        .closest(".shibutz")
        .querySelector(".spacePicker")
        .classList.remove("hide");
      const headerText =
        index == "new" ? "הוסיפו שיבוץ לתאריך:" : `שנו שיבוץ ל${spaceName}:`;
      target
        .closest(".shibutz")
        .querySelector(".spacePicker")
        .querySelector(".spacePicker--header").textContent = headerText;
      target
        .closest(".shibutz")
        .querySelector(".spacePicker")
        .classList.toggle("dropup", index != "new");
      this.check_choosen_spaces(target);
    });
  }
  close_picker(e) {
    e.target
      .closest(".shibutz")
      .querySelector(".spacePicker")
      .classList.add("hide");
  }
  check_choosen_spaces(target) {
    const spacesToPick = target
      .closest(".shibutz")
      .querySelector(".spacePicker")
      .querySelectorAll(".js-spaceToPick");
    const pickedSpaces = [
      ...document.querySelectorAll(
        '.js-space-row:not([style="display: none;"])'
      ),
    ].map((spaceRow) => spaceRow.querySelector(".set-space").value);
    spacesToPick.forEach((spaceToPick) => {
      spaceToPick.classList.toggle(
        "choosen",
        pickedSpaces.includes(spaceToPick.dataset.space)
      );
    });
  }
  check_double_spaces() {
    const pickedSpaces = [
      ...document.querySelectorAll(
        '.js-space-row:not([style="display: none;"])'
      ),
    ].map((spaceRow) => spaceRow.querySelector(".set-space").value);
    const toFindDuplicates = (arry) =>
      arry.filter((item, index) => arry.indexOf(item) !== index);
    const duplicatePickedSpaces = toFindDuplicates(pickedSpaces);
    document
      .querySelectorAll('.js-space-row:not([style="display: none;"])')
      .forEach((spaceRow) => {
        const space = spaceRow.querySelector(".set-space");
        space.parentElement.classList.toggle(
          "double",
          duplicatePickedSpaces.includes(space.value)
        );
        const spaceLabel = spaceRow.querySelector(".spaceLabel");
        if (spaceLabel)
          spaceLabel.classList.toggle(
            "double",
            duplicatePickedSpaces.includes(space.value)
          );
        space.classList.toggle(
          "double",
          duplicatePickedSpaces.includes(space.value)
        );
      });
  }
  add_space_classes(e, index) {
    const kind = e.target.dataset.kind;
    const className = e.target.className.includes("double")
      ? "double " + e.target.dataset.class
      : e.target.dataset.class;
    document.querySelector(`#space-select-${index}`).className =
      "grouped_select optional newInput set-space choosen get-" +
      kind +
      "-price " +
      className;
    document.querySelector(`#space-select-${index}-label`).className =
      "newInput right choosen spaceLabel " + className;
    document.querySelector(`#space-select-${index}`).parentElement.className =
      "choosenWrapper choosen flex " + className;
  }
  choose_space(e) {
    const picker = e.target.closest(".shibutz").querySelector(".spacePicker");
    const index = picker.dataset.chunk;

    if (index == "new") this.add_chunk(e);
    else {
      document.querySelector(`#space-select-${index}`).value =
        e.target.dataset.space;
      document.querySelector(`#space-select-${index}-label`).value =
        e.target.textContent;
      this.add_space_classes(e, index);
    }
    if (picker.className.includes("dropup")) {
      this.close_picker(e);
    } else {
      e.target.classList.add("choosen");
    }

    this.check_double_spaces();
  }

  filter_by_status(e) {
    const target = e.currentTarget;
    const picker = target.closest(".shibutz").querySelector(".spacePicker");
    const container = picker.querySelector(".spacePicker-availableContainer");
    const status = target.dataset.status;
    container.classList.toggle(`show-${status}`);
    target.classList.toggle("btn-secondary");
  }
  add_chunk(e) {
    const kind = e.target.dataset.kind;
    const time = new Date().getTime();
    //const start_date = document.querySelector('#event_start_date').value
    //const end_date = document.querySelector('#event_end_date').value
    const start_date = e.target.dataset.start;
    const end_date = e.target.dataset.end;
    let template, add, towels, sheets;
    if (kind == "room") {
      template = this.roomTemplateTarget;
      add = this.addRoomsTarget;
      towels = parseInt(
        document.querySelector("#customer-info-towels").innerHTML
      );
      sheets = parseInt(
        document.querySelector("#customer-info-sheets").innerHTML
      );
    }
    if (kind == "classroom") {
      template = this.classroomTemplateTarget;
      add = this.addClassroomsTarget;
    }
    if (kind == "equipment") {
      template = this.equipmentTemplateTarget;
      add = this.addEquipmentsTarget;
    }
    const content = template.innerHTML.replace(/NEW_RECORD/g, time);

    add.insertAdjacentHTML("beforeend", content);
    document.querySelector(
      `#event_chunks_attributes_${time}_start_date`
    ).value = start_date;
    document.querySelector(`#event_chunks_attributes_${time}_end_date`).value =
      end_date;
    document
      .querySelector(`#event_chunks_attributes_${time}_start_date`)
      .setAttribute("value", start_date);
    document
      .querySelector(`#event_chunks_attributes_${time}_end_date`)
      .setAttribute("value", end_date);
    document
      .querySelector(`#space-select-${time}`)
      .setAttribute("value", e.target.dataset.space);
    document.querySelector(`#space-select-${time}-label`).value =
      e.target.textContent;

    document.querySelector(`#space-select-${time}`).value =
      e.target.dataset.space;
    if (kind == "room") {
      document.querySelector(
        `#event_chunks_attributes_${time}_towels`
      ).checked = towels;
      document.querySelector(
        `#event_chunks_attributes_${time}_sheets`
      ).checked = sheets;
      if (towels)
        document
          .querySelector(`#event_chunks_attributes_${time}_towels`)
          .setAttribute("checked", towels);
      if (sheets)
        document
          .querySelector(`#event_chunks_attributes_${time}_sheets`)
          .setAttribute("checked", sheets);
    } else if (kind == "classroom" || kind == "equipment") {
      const diffDays = Math.ceil(
        (Date.parse(end_date) - Date.parse(start_date)) /
          (1000 * 60 * 60 * 24) +
          1
      );
      document.querySelector(`#num-of-days-${time}`).value = diffDays;
      document
        .querySelector(`#num-of-days-${time}`)
        .setAttribute("value", diffDays);
    }

    this.add_space_classes(e, time);
    this.set_space();
    this.eventsController.get_classroom_price({
      target: document.querySelector(`#space-select-${time}`),
    });
    this.eventsController.set_event_final_price();
    //this.eventsController.set_taken(time)
    console.log(
      "this.eventsController.set_event_final_price() from order#add_chunk"
    );
    this.set_dates(e);
  }

  remove_space(e) {
    let wrapper = e.target.closest("tr");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
    this.check_double_spaces();
    this.eventsController.set_event_final_price();
  }
  toggle(e) {
    // const div = e.target.closest('.order-div')
    e.target.closest(".order-div").classList.toggle("show");
    // div.querySelector('.order-table').classList.toggle('show')
  }
  clone_space(e) {
    const time = new Date().getTime();
    const tr = e.target.closest("tr");
    const kind = tr.dataset.kind;
    const index = tr.dataset.index;
    let add;
    if (kind == "room") {
      add = this.addRoomsTarget;
    } else {
      add = this.addClassroomsTarget;
    }
    let start_date, end_date, towels, sheets, num_in_room, notes, content;

    const select2 = tr.querySelector(".select2");
    $(select2).select2("destroy");
    content = tr.outerHTML
      .replaceAll(
        `[chunks_attributes][${index}]`,
        `[chunks_attributes][${time}]`
      )
      .replaceAll(`chunks_attributes_${index}`, `chunks_attributes_${time}`)
      .replace(`data-index="${index}"`, `data-index="${time}"`);
    if (kind == "classroom") {
      content = content
        .replace(`num-of-days-${index}`, `num-of-days-${time}`)
        .replace(`chunk_td_unit_price_${index}`, `chunk_td_unit_price_${time}`)
        .replace(
          `chunk_td_total_price_${index}`,
          `chunk_td_total_price_${time}`
        );
    }
    add.insertAdjacentHTML("beforeend", content);
    $(".select2").select2();
    const new_tr = add.querySelector("tr:last-of-type");
    const new_select2 = new_tr.querySelector("select.select2");
    if ($(new_select2).attr("value") != undefined) {
      $(new_select2).val($(new_select2).attr("value")).trigger("change");
    }
    this.set_space();

    new_tr.querySelector("textarea").value = new_tr
      .querySelector("textarea")
      .getAttribute("value");
    // document.querySelector(`#event_chunks_attributes_${time}_unit_price`).value = diffDays
    // document.querySelector(`#event_chunks_attributes_${time}_unit_price`).setAttribute("value", diffDays)
    this.eventsController.set_event_final_price();
    this.eventsController.set_taken(e);
  }

  clone_from_order(e) {
    let index;
    const tr = document.querySelector(`#${e.target.value}`);
    const template = this.roomTemplateTarget;
    const add = this.addRoomsTarget;
    const start_date = tr.querySelector("input[name*='start_date'").value;
    const end_date = tr.querySelector("input[name*='end_date'").value;
    const towels = tr.querySelector("input.towels").checked;
    const sheets = tr.querySelector("input.sheets").checked;
    const beds_per_night = tr.querySelector(
      "input[name*='beds_per_night'"
    ).value;
    const notes = tr.querySelector("input[name*='notes'").value;
    const room_amount = parseInt(tr.querySelector(".room-amount").innerHTML);
    for (let i = 0; i < room_amount; i++) {
      index = new Date().getTime() + i;
      const content = template.innerHTML.replace(/NEW_RECORD/g, index);
      add.insertAdjacentHTML("beforeend", content);
      document.querySelector(
        `#event_chunks_attributes_${index}_start_date`
      ).value = start_date;
      document.querySelector(
        `#event_chunks_attributes_${index}_end_date`
      ).value = end_date;
      document.querySelector(
        `#event_chunks_attributes_${index}_towels`
      ).checked = towels;
      document.querySelector(
        `#event_chunks_attributes_${index}_sheets`
      ).checked = sheets;
      document.querySelector(
        `#event_chunks_attributes_${index}_num_in_room`
      ).value = beds_per_night;
      document.querySelector(`#event_chunks_attributes_${index}_notes`).value =
        notes;
    }

    $(".select2").select2();
    this.set_space();
    this.eventsController.set_event_final_price();
    this.eventsController.set_taken(e);
  }
  set_chunks_checkbox(e) {
    const type = e.target.dataset["type"];
    const classSelector = `.event_chunks_${type}`;
    const table = e.target.closest("table");
    const state = e.target.checked;
    table
      .querySelectorAll(classSelector)
      .forEach((div) =>
        div.querySelectorAll("input").forEach((ipt) => (ipt.checked = state))
      );
  }
  set_value(e) {
    if (e.target.getAttribute("type") == "checkbox") {
      const checked = e.target.checked;
      if (checked) {
        e.target.setAttribute("checked", checked);
      } else {
        e.target.removeAttribute("checked");
      }
    } else {
      const value = e.target.value;
      e.target.setAttribute("value", value);
    }
    // this.eventsController.set_event_final_price()
    this.eventsController.set_event_sub_totals();
  }

  set_space() {
    this.check_double_spaces();

    document.querySelectorAll(".get-classroom-price").forEach((item) => {
      item.addEventListener("change", (e) => {
        this.eventsController.get_classroom_price(e);
      });
    });

    this.eventsController.set_event_final_price();
  }

  set_select_space_availability() {
    const selected = [];

    document.querySelectorAll(".set-space").forEach((item) => {
      const groups = Array.from(item.children);
      groups.forEach((g) => {
        const options = Array.from(g.children);
        options.forEach((o) => {
          if (selected.includes(o.value)) {
            o.classList.add("highlight");
          } else if (!selected.includes(o.value)) {
            o.classList.remove("highlight");
          }
        });
      });
    });
  }

  set_dates(e) {
    const start_date = Date.parse(
      document.querySelector("#event_start_date").value
    );
    const end_date = Date.parse(
      document.querySelector("#event_end_date").value
    );

    const diffDays = end_date - start_date;

    if (start_date) {
      const elems = document.querySelectorAll(".set_start_date");
      elems.forEach((el) =>
        el.setAttribute(
          "min",
          document.querySelector("#event_start_date").value
        )
      );
    }
    if (end_date) {
      const elems = document.querySelectorAll(".set_end_date");
      elems.forEach((el) =>
        el.setAttribute("max", document.querySelector("#event_end_date").value)
      );
    }

    if (diffDays < 0) {
      document.querySelector("#event_end_date").value = "";
      document.querySelector(".dates-error").classList.add("show");
    } else {
      document.querySelector(".dates-error").classList.remove("show");
    }
  }

  setMealRowColumn(e) {
    const type = e.target.dataset.mealtype;
    const date = e.target.dataset.mealdate;
    console.log(e.target.dataset.mealtype);
    const checks = document.querySelectorAll("input.one-meal");
    if (type) {
      checks.forEach((el) => {
        if (el.getAttribute("data-mealtype") === type || type === "all") {
          el.checked = e.target.checked;
        }
      });
    }
    if (date) {
      checks.forEach((el) => {
        if (el.getAttribute("data-mealdate") === date) {
          el.checked = e.target.checked;
        }
      });
    }
  }

  addMealWizard(e) {
    const checks = document.querySelectorAll("input.one-meal");
    checks.forEach((el) => {
      if (el.checked === true) {
        const type = el.getAttribute("data-mealtype");
        const date = el.getAttribute("data-mealdate");
        const participant = document.getElementById("wizard-participant").value;
        const data = { time: {}, vegan: {}, veggy: {} };

        data.time[type] = document.getElementById(
          `wizard-meal-time-${type}`
        ).value;
        data.vegan.num = document.getElementById(`wizard-vegan_num`).value;
        data.vegan.notes = document.getElementById(`wizard-vegan_notes`).value;
        data.veggy.num = document.getElementById(`wizard-veggy_num`).value;
        data.veggy.notes = document.getElementById(`wizard-veggy_notes`).value;
        data.alergies = document.getElementById(`wizard-alergies`).value;
        console.log(data.time[type], toString(data.time[type]));
        this.add_meal(e, type, date, participant, data);
      }
    });
    this.eventsController.toggleWizard();
  }
}
