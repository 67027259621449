import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
    initialize() {
        this.element[this.identifier] = this

    }
    connect() {
        StimulusReflex.register(this)
        
        document.addEventListener('cable-ready:after-inner-html', () => {
            if (this.main().classList.contains('wait')) {
                this.main().classList.remove('wait')
            }
        })
          
    }
    main() {
            return document.querySelector('main')
        }
        /* Application-wide lifecycle methods
         *
         * Use these methods to handle lifecycle concerns for the entire application.
         * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
         *
         * Arguments:
         *
         *   element - the element that triggered the reflex
         *             may be different than the Stimulus controller's this.element
         *
         *   reflex - the name of the reflex e.g. "Example#demo"
         *
         *   error/noop - the error message (for reflexError), otherwise null
         *
         *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
         */

    beforeReflex(element, reflex, noop, reflexId) {
        if (reflex == 'Calendar#choose_day' || reflex == 'Calendar#desk_info' || reflex == 'Calendar#event' || reflex == 'Calendar#event_edit') {
            const modal = document.querySelector(".modal-wrapper")
            modal.style.display = 'initial'
            this.main().style.overflow = 'hidden'
            setTimeout(() => {
                modal.classList.remove("inactive")
            }, 200);
        } else {
            this.main().classList.remove('no-wait')
            this.main().classList.add('wait')
        }

    }

    reflexSuccess(element, reflex, noop, reflexId) {
        // show success message
        // console.log("reflexSuccess");
        if (reflex == "Events#quick_event_status") {
            if (this.main().classList.contains('wait')) {
                this.main().classList.remove('wait')
            }
        }
    }

    reflexError(element, reflex, error, reflexId) {
        console.log("reflexError");
        if (this.main().classList.contains('wait')) {
            this.main().classList.remove('wait')
        }
        // const toast = document.querySelector('#toast')
        // toast.textContent = error
        // toast.classList.add('show')
        // setTimeout(() => {
        //     toast.classList.remove('show')
        // }, 2000);

    }

    reflexHalted(element, reflex, error, reflexId) {
        // console.log("reflexHalted");
        // handle aborted Reflex action
        if (this.main().classList.contains('wait')) {
            this.main().classList.remove('wait')
        }
    }

    afterReflex(element, reflex, noop, reflexId) {
    }

    finalizeReflex(element, reflex, noop, reflexId) {
        // all operations have completed, animation etc is now safe
        if (this.main().classList.contains('wait')) {
            this.main().classList.remove('wait')
        }

    }
    finalizeChoose(element) {
        if (element.classList.contains('time-input')) {
            const btns = document.querySelectorAll('.kind-btn')
            btns.forEach(btn => {
                btn.dataset.time = element.dataset.time
                btn.value = element.value
            })
        } else {
            const inputs = document.querySelectorAll('.time-input')
            inputs.forEach(input => {
                input.dataset.kind = element.dataset.kind
            })
        }
    }
    finalizeChooseCustomer(e) {
        document.querySelector('.room-meal-order').classList.add('show')
    }
    finalizeEventStatus(element) {

    }
    updateEventCal(element) {
        const toast = document.querySelector('#toast')
        toast.textContent = 'סטטוס עודכן בהצלחה'
        toast.classList.add('show')
        setTimeout(() => {
            toast.classList.remove('show')
        }, 2000);
        const eventId = element.dataset.event
        const newClass = `${element.value}-bg`
        document.querySelectorAll(`.event-${eventId}`).forEach(e => {
            // e.className.replace(/\b-bg.*?\b/g, '')
            const classes = e.className.split(' ').map(className => className.includes('-bg') ? '' : className)
            classes.push(newClass)
            e.className = classes.join(' ')
        })
        if (this.main().classList.contains('wait')) {
            this.main().classList.remove('wait')
        }
    }
    afterEventStatus(element) {
        if (this.main().classList.contains('wait')) {
            this.main().classList.remove('wait')
        }
        this.updateEventCal(element)
    }
    afterStatusIndex(element) {
        const newClass = `${element.value}-bg`
        const classes = element.className.split(' ').map(className => className.includes('-bg') ? '' : className)
        classes.push(newClass)
        element.className = classes.join(' ')
    }

    beforeOpenSpace() {
        const modal = document.querySelector(".modal-wrapper")
        modal.style.display = 'initial'
            // this.main().style.overflow = 'hidden'
        setTimeout(() => {
            modal.classList.remove("inactive")

        }, 200);
    }
    beforeOpenDay() {
        const modal = document.querySelector(".modal-wrapper")
        modal.style.display = 'initial'
            // this.main().style.overflow = 'hidden'
        setTimeout(() => {
            modal.classList.remove("inactive")

        }, 200);
    }

    finalizeEvent() {
        // const classroom_rows = document.querySelectorAll('.classroom-rows');
        // classroom_rows.forEach(row => {
        //     const start_date = Date.parse(row.querySelector(".start-date").value)
        //     const end_date = Date.parse(row.querySelector(".end-date").value)
        //     const diff_days = Math.ceil((end_date - start_date) / (1000 * 60 * 60 * 24)) + 1
        //     row.querySelector(".classroom-amount").innerHTML = diff_days
        //     console.log(row.querySelector(".unit-price"))
        //     const unit_price = row.querySelector(".unit-price").value
        //     row.querySelector(".unit-price").value = unit_price
        //     row.querySelector(".total-price").innerHTML = diff_days * unit_price
        // })
    }
}
