// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu2","menu1", 'mobileMenu',"screen","installBtn" ]
  connect(){
    

    if (this.hasInstallBtnTarget) {
      // Initialize deferredPrompt for use later to show browser install prompt.
      let deferredPrompt;

      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        //showInstallPromotion();
        // Optionally, send analytics event that PWA install promo was shown.
        console.log(`'beforeinstallprompt' event was fired.`);
      });

      this.installBtnTarget.addEventListener('click', async () => {
        // Hide the app provided install promotion
        //hideInstallPromotion();
        // Show the install prompt
        if (deferredPrompt) {
        
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          const { outcome } = await deferredPrompt.userChoice;
          // Optionally, send analytics event with outcome of user choice
          console.log(`User response to the install prompt: ${outcome}`);
          // We've used the prompt, and can't use it again, throw it away
          deferredPrompt = null;
        }
        if (!deferredPrompt) {
          this.installBtnTarget.textContent = "התכנה הותקנה בהצלחה"
        }

      });
      if (!deferredPrompt) {
        this.installBtnTarget.textContent = "התכנה מותקנת בהצלחה"
        this.installBtnTarget.disabled = true
      }
    }
  }
  toggleSideNav(e) {
    document.querySelector('.side-nav').classList.toggle('hide-small')
    document.querySelector('.side-nav').classList.toggle('show-small')
  }

  click1(e){
    e.stopPropagation();
    this.menu1Target.classList.toggle('show')

    
  }

  click2(e){
    e.stopPropagation();
    this.menu2Target.classList.toggle('show')
    
  }

  clickMobile(e){
    if (e.target.dataset.type=='mobile') {
      this.mobileMenuTarget.classList.toggle('hide')
      this.screenTarget.classList.toggle('backdrop')
    }
    
  }
  install() {
    console.log("install")
  }

  expandSideNav(e) {
    const target = e.target;
    const container = target.closest('div')
    const icon = target.className.includes('material-icons') ? target : target.querySelector('.material-icons')
    
    container.className = icon.textContent == "arrow_back" ? "expand-side-bar--layout window" : "small-side-bar--layout window" 
    document.querySelector('.side-nav').classList.toggle('expand')
    icon.textContent = icon.textContent == "arrow_back" ? "arrow_forward" : "arrow_back"
  }


}
