// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import ApplicationController from './application_controller'
import $, { event } from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';


import Selectables from "./selectable.js"

import ContextMenu from "../packs/contextMenu.js"

export default class extends ApplicationController {
  static targets = [ 'menu', 'vacancyTemplate', 'addVacancies', 'roomTemplate', 'classroomTemplate', 'addRoom', 'addClassroom', 'modalWrapper' ]

  afterReflex(){
    this.connect() 
  }

  connect() {
    super.connect()
    const that = this
    
    if (document.querySelector('#spaces')){
      const selsctableTable = new Selectables({
        elements: 'tr',
        skipElements: ['a','button','span'],
        selectedClass: 'selected-row',
        zone: '#spaces',
        moreUsing: 'ctrlKey',
        stop: function(e) {
          console.log(e)
          that.roomCounter()
          document.querySelectorAll('thead tr').forEach(tr => tr.classList.remove('selected-row'))
        }
      });
    }
    if (document.querySelector('#space-table')){
      
      const selsctableTable = new Selectables({
        elements: 'td',
        skipElements: ['a','button','span'],
        selectedClass: 'selected-td',
        zone: '#space-table tbody',
        moreUsing: 'ctrlKey',
        stop: function(e) {
        }
      });
      console.log(selsctableTable)
      const contextMenu = new ContextMenu(that,{})

    }




  }
  add_vacancy(e){
    var content = this.vacancyTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.addVacanciesTarget.insertAdjacentHTML('beforebegin', content)
  }
  remove_vacancy(e) {

   let wrapper = e.target.closest(".nested-vacancies")

   // New records are simply removed from the page
   if (wrapper.dataset.newRecord == "true") {
     wrapper.remove()

   // Existing records are hidden and flagged for deletion
   } else {
     wrapper.querySelector("input[name*='_destroy']").value = 1
     wrapper.style.display = 'none'
   }
 }

     add_room(){
        const content = this.roomTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
        this.addRoomTarget.insertAdjacentHTML('beforebegin', content)
       $(".select2").select2()
     }

    add_classroom(){
        var content = this.classroomTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.addClassroomTarget.insertAdjacentHTML('beforebegin', content)
        $(".select2").select2()
    }

     update_night_amount(e) {
         const tr = e.target.closest("tr")
         const start_date = tr.querySelector("[type=date]:first-of-type").value
         const end_date = tr.querySelector("input[name*='end_date']").value
         const night_amount = tr.querySelector(".night_amount")
         var date1 = new Date(start_date);
         var date2 = new Date(end_date);
        // To calculate the time difference of two dates
         var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
         var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
         night_amount.innerHTML = Difference_In_Days
     }

    update_day_amount(e) {
        const tr = e.target.closest("tr")
        const start_date = tr.querySelector("[type=date]:first-of-type").value
        const end_date = tr.querySelector("input[name*='end_date']").value
        const day_amount = tr.querySelector(".day_amount")
        var date1 = new Date(start_date);
        var date2 = new Date(end_date);
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        day_amount.innerHTML = Difference_In_Days + 1
    }
    close(){
      console.log("close");
      const modal = this.modalWrapperTarget
      modal.classList.add("inactive")
      setTimeout(() => {
        console.log(2)

        modal.style.display = 'none'
        document.querySelector("#spaces-modal-content").innerHTML = ''
        document.body.style.overflow = 'auto'
      }, 200);
    }
    menu(){
      this.menuTarget.classList.toggle('show')
    }

    filterSpaces(event) {
      this.setActiveButton(event.target)
   
      const value = event.target.dataset.value 
      const occ = document.querySelectorAll(".occupied");
      const all = document.querySelectorAll("tr.space-row");

  
      if (value == 'occupied') {
        all.forEach(el => {
          return el.className = 'space-row hide';
        })
        occ.forEach(el => {
          return el.parentNode.className = 'space-row show';
        })
      }
      else if (value == 'vaccant') {
        all.forEach(el => {
          return el.className = 'space-row show';
        })
        occ.forEach(el => {
          return el.parentNode.className = 'space-row hide';
        })
      }
      else {
        all.forEach(el => {
          return el.className = 'space-row show';
        })
      }


    }

    filterSearch(event) {
      
      const all = document.querySelectorAll("tr.space-row");
            // Declare variables
            var  filter,  td, tds, i, txtValue, spans, n;

            filter = event.target.value;
      
      
            // Loop through all table rows, and hide those who don't match the search query
            for (n = 0; n< all.length; n++) {
              tds = all[n].getElementsByTagName("td");
              var isFound = false
              for (i = 0; i < tds.length; i++) {
                td = tds[i]
                if (td) {
                  txtValue = td.textContent || td.innerText;
                  try {spans = td.childNodes
                  spans.forEach(button => console.log(button.firstChild()))} catch {}
                  if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    isFound = true
                    break
                  }
                }
                
              }
              if (isFound) {
                all[n].style.display = "table-row";
              } else {
                all[n].style.display = "none";
              }
            }
    }

    filterHouses(event) {
      this.setActiveButton(event.target)
      const houseSelect = document.querySelector("#houseSelect")
      houseSelect.textContent = event.target.textContent
      if (houseSelect.textContent === 'כל הבתים') {
        houseSelect.classList.remove('active')
      } else {
        houseSelect.classList.add('active')
      }
      const houseId = event.target.dataset.value 
      const house = document.querySelector(`.house-${houseId}`);
      const houses = document.querySelectorAll(".house-div");
      houses.forEach(h => {
        if (houseId === 'all') {
          h.classList.add('show')
          h.classList.remove('hide')
        } else {
          h.classList.add('hide')
          h.classList.remove('show')
        }
      })
      if (houseId !== 'all') {
      house.classList.add('show')
      house.classList.remove('hide')
      }
    }

    filterHousesType(event) {
      this.setActiveButton(event.target)
      const type = event.target.dataset.value 
      const house = document.querySelectorAll(`.house-div.${type}`);
      const houses = document.querySelectorAll(".house-div");
      houses.forEach(h => {
        if (type === 'all') {
          h.classList.add('show')
          h.classList.remove('hide')
        } else {
          h.classList.add('hide')
          h.classList.remove('show')
        }
      })
      house.forEach(h => {
          h.classList.add('show')
          h.classList.remove('hide')
      })
    }


    tableLayout(event) {
      this.setActiveButton(event.target)
      const classname = event.target.dataset.value 
      const tabels = document.querySelectorAll("table");

      tabels.forEach(t => {
        t.className = classname
      })
    }

    setActiveButton (element) {
      console.log(element)
      console.log(element.dataset.kind)
      if (element.dataset.kind) {
        const actives = document.querySelectorAll(".active");
        actives.forEach(a => a.classList.remove('active'))
      } else {
        const parent = element.parentNode
        const children = parent.children
        for (let child of children) {
          child.classList.remove('active')
        }
        element.classList.add('active')
      }
    }

    setTime(element) {
      this.setActiveButton(element.target)
      const value = element.target.dataset.value 
      const input = document.querySelector("#dateIpt")
      const btn = document.querySelector("#dateBtn")
      input.setAttribute("data-time", value);
      btn.textContent = element.target.textContent

      if (value === "custom" ) {
        document.querySelector("#endDate").style.display = 'flex'
      } else {
        document.querySelector("#endDate").style.display = 'none'
        input.dispatchEvent(new Event("input"))

      }
    }

    chooseEndDate(event) {
      const date = event.target.value
      const input = document.querySelector("#dateIpt")
      input.setAttribute("data-end", date);
      input.dispatchEvent(new Event("input"))
    }

    rowSelectionChanged(data, rows, tableDiv) {
      tableDiv.closest('div.spaces-table-wrapper').querySelector('div.actions span').innerHTML = data.length
      let text =""
      data.forEach(e => {
        text += e.id +","
      })
      console.log(data)
    }
    

    //row selection and count

    /*selectRow(e) {
      if (!e.ctrlKey) {this.clearSelectedRows(); console.log(e.ctrlKey);}
      console.log(e.target)
      e.target.parentNode.classList.toggle('selected-row')
      this.roomCounter()
    }

    selectRows(elements) {
      if (!e.ctrlKey) {this.clearSelectedRows();}
      this.roomCounter()
    }*/

    clearSelectedRows(){
      const rows = document.querySelectorAll('.selected-row')
      rows.forEach(room => room.classList.remove('selected-row'))
      const ipt = document.getElementById('room_sum').value = ''
      const tools = document.querySelectorAll('.sum-tools').forEach(tool => tool.style.display = "none")
    }
   
    roomCounter(){
      document.querySelectorAll('.sum-tools').forEach(tool => tool.style.display = "block")
      console.log('count')
      let room_sum = 0;
      let bed_sum = 0;
      let class_sum = 0;

      const rooms = document.querySelectorAll('.selected-row')
        rooms.forEach(room => {
          
          room.dataset.kind === 'room' ? room_sum ++ : room_sum
          room.dataset.kind === 'classroom' ? class_sum ++ : 
          bed_sum = bed_sum + (parseInt(room.dataset.bed_num, 10) || 0)
        })
      if((room_sum + bed_sum + class_sum) === 0) {document.querySelectorAll('.sum-tools').forEach(tool => tool.style.display = "none")}
      const ipt = document.getElementById('room_sum')
      ipt.value = `נבחרו ${bed_sum} מיטות ב ${room_sum} חדרים. ${class_sum} כיתות`
    }

    choose_date(e){
  	const date = e.target.value
		const url = new URL(window.location.href);
		console.log(url)
		url.searchParams.set("start_date", date)
		window.location.href = url
    
	}

  move_chunk(e) {
    const that = this
    const chunk_id = e.target.dataset.chunk
    function getClick() {
      return new Promise(acc => {
        function handleClick(e) {
          document.removeEventListener('click', handleClick);
          acc(e);
        }
        document.addEventListener('click', event => {handleClick(event)});
      });
    }
    
    
    async function main() {
      
      for (let i=0;i<3;i++) {
        console.log(i,"click")
        document.querySelectorAll(".side-1").forEach(td => {td.classList.add("clickable")})
        const e = await  getClick();
        const new_space = parseInt(e.target.dataset.space, 10);
        if (typeof(new_space) == "number" && i == 2 && e.target.classList.contains('clickable')) {
          that.stimulate("Spaces#move_chunk", chunk_id, new_space)
        }
        document.querySelectorAll(".side-1").forEach(td => td.classList.remove("clickable"))
      }
    }
    
    main();
    
  }

  open_chunk_modal(e) {
    const data = JSON.parse(e.target.dataset.date)
    const kind = e.target.dataset.kind
    const wrapper = document.getElementById('chunk-modal')
    wrapper.style.display = "block"
    const start_date = new Date(Math.min(...data.map(e => new Date(e[0]))))
    const end_date = new Date(Math.max(...data.map(e => new Date(e[1]))))
    this.stimulate("spaces#open_chunk_modal",start_date, end_date, kind).then(t => {
      const cancel = document.getElementById('cancelBtn')
      const ok = document.getElementById('okBtn')

      wrapper.addEventListener("click", event => {
        const target = event.target
        if (target.dataset.checkbox && target.dataset.checkbox == "checkbox") {
          console.log(target.checked)
        } else {
          event.preventDefault()
        }
        
        
        if (target == wrapper || target == cancel ) {
          wrapper.style.display = "none"
        }
        
        if (target == ok ) {
          wrapper.style.display = "none"
          const select = document.getElementById('event-select')
          const event_id = select.options[select.selectedIndex].value;
          const num_in_room = document.getElementById('num_in_room').value
          const unit_price = document.getElementById('unit_price').value
          const sheets = document.getElementById('sheets').checked
          const towels = document.getElementById('towels').checked
          this.stimulate("spaces#new_chunk",data, {event_id,num_in_room,unit_price,sheets,towels},kind )
        }

      })
    })
  }
  
  open_availability_modal(e){
    const data = JSON.parse(e.target.dataset.date);
    const space_ids = data.map(subArray => subArray[2]);
    const kind = e.target.dataset.kind
    const action_type = e.target.dataset.actiontype
    const wrapper = document.getElementById('chunk-modal')
    wrapper.style.display = "block"
    const start_date = new Date(Math.min(...data.map(e => new Date(e[0]))))
    const end_date = new Date(Math.max(...data.map(e => new Date(e[1]))))
    this.stimulate("spaces#open_availability_modal",start_date, end_date, action_type, kind, space_ids).then(t => {
    const cancel = document.getElementById('cancelBtn')
    const ok = document.getElementById('okBtn')
    wrapper.addEventListener("click", event => {
        event.preventDefault()
        const target = event.target
        if (target == wrapper || target == cancel ) {
          wrapper.style.display = "none"
        }
        if (target == ok ) {
          wrapper.style.display = "none"
          const title = document.getElementById('space_vacancies_attributes_title')
          if (action_type === "unavailable"){
            this.stimulate("spaces#new_unavailility",space_ids,start_date, end_date, title )
          }else{
            this.stimulate("spaces#new_availility",space_ids,start_date, end_date, title )
          }
        }
      })
    })
  }


}
