import ApplicationController from './application_controller'

export default class extends ApplicationController {
    connect () {
        super.connect()
        // add your code here, if applicable
        const table = document.getElementById("timeTableDiv").querySelector("table")

        document.addEventListener('click', e =>{ 
            const tds = document.querySelectorAll(".js-notes-td")
            if (e.target.querySelector(".js-ipt")) return
            tds.forEach(td => {
                console.log(td == e.target)
                if (td == e.target) {
                    td.insertAdjacentHTML('beforeend', this.getInputAndButtons());
                    td.querySelector("input").focus()
                } 
            })
            
            
            let buttonClick = false
            if(e.target.classList.contains("js-save")) {
                buttonClick = true
                const data = {
                    title: e.target.closest('td.js-notes-td').querySelector(".js-ipt").value, 
                    date: e.target.closest('td.js-notes-td').dataset.date, 
                    time: e.target.closest('td.js-notes-td').dataset.time, 
                    kind: "notes"}
                this.stimulate("Notes#create_note", data.title, data.date, data.time, data.kind )
            }
            if(e.target.classList.contains("js-cancel")) {
                buttonClick = true
            }

            this.removeInputs(e.target.closest('td.js-notes-td'),buttonClick)
        })
      }
    openNoteInput(e) {

    }

    removeInputs(td, buttonClick) {
        const ipts =  document.querySelectorAll(".js-ipt-box")
        ipts.forEach(ipt => {
            if (!td || buttonClick || td.querySelector('.js-ipt-box') != ipt) ipt.remove()
        })  
    }

    getInputAndButtons() {
        const html = `
        <div class="js-ipt-box input-box">
            <input class='js-ipt input w-100'>
            <div class='input-box__float-buttons'>
                <button type="button" class="btn small js-save">שמירה</button>
                <button type="button" class="btn small js-cancel">ביטול</button>
            </div>
        </div>
        `
        return html
    }

    setDateParam(e) {
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search);
		url.searchParams.set('date', e.target.value);
		window.history.pushState("", "", url);
	}

    close(){
		const modal = document.querySelector(".modal-wrapper")
		modal.classList.add("inactive")
		setTimeout(() => {
			console.log(12)

			modal.style.display = 'none'
			document.querySelector("#calendar-modal-content").innerHTML = ''
			document.body.style.overflow = 'auto'
		}, 200);
	}
}