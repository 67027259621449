import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
  }
  choose_kind(e) {
    console.log(e);
    const card = e.target.closest(".timeline__card");
    document.querySelectorAll(".timeline__card").forEach((c) => {
      c.classList.toggle("grow", c == card && !card.className.includes("grow"));
    });
    const info = document.getElementById(e.target.dataset.info);
    document.querySelectorAll(".timeline__moreInfo").forEach((c) => {
      c.classList.toggle("show", c == info && !info.className.includes("show"));
    });
  }
}
