import ApplicationController from  '../application_controller'

export default class extends ApplicationController {
    static targets = ["search","close","panel","go","header"]
  connect(){
    super.connect()
    const that = this
    document.addEventListener('keypress', function (e) {
        if (e.key === 'Escape') {
            that.close();
        }
    });
    this.searchTarget.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            that.search();
        }
    });
    this.goTarget.addEventListener("click",e => {
        this.search();
    })
    this.closeTarget.addEventListener("click",e => {
        this.close();
    })
  }
  close(e) {
    this.panelTarget.classList.remove("open")
    this.searchTarget.value = ""
  }
  search(e) {
    if (this.searchTarget.value == "" ) return;
    this.panelTarget.classList.add("open")
    const date = this.getDate(this.searchTarget.value)
    if (!date) {
      this.stimulate("Search#search", this.searchTarget.value,3)
      this.headerTarget.textContent = "תוצאות חיפוש עבור: " + this.searchTarget.value

    } else {
      this.stimulate("Search#search_date", date,3)
      const d = new Date(date) 
      console.log(d);
      this.headerTarget.textContent = "תוצאות חיפוש עבור: " + (d.getDate()-1) + "/"+ (d.getMonth()+1) + "/"+d.getFullYear()


    }
    
  }
  full_results(e){
    const date = this.getDate(this.searchTarget.value)
    if (!date) {
    window.location.href = "/search?q=" + this.searchTarget.value
    } else {
      const d = new Date(date) 
      window.location.href = "/search?q=" + d.getFullYear() + "-"+ (d.getMonth()+1) + "-"+ (d.getDate()-1) 
    }
    this.close();
  }

  getDate(str) {

    let [day, month, year] = str.split('/');

    if (year && year.length == 2) year = "20" + year
    try {
      return new Date(parseInt(year),parseInt(month)-1,parseInt(day)+1).toISOString()
    }catch(err){
      return null
    };
     
  }
}