import ApplicationController from './application_controller'


export default class extends ApplicationController {
    static targets = [ 'table']
    connect() {
    super.connect()

    
    const date = new Date();
    const hour = this.addTrailingZerow(date.getHours())
    const minute = this.addTrailingZerow(date.getMinutes() )
    const time = hour + ":" + minute
    console.log(date.getHours());
    console.log(date.getMinutes());
    console.log([...this.tableTarget.querySelectorAll('.js-time')].map(td => td.dataset.time));
    this.tableTarget.querySelectorAll('.js-time').forEach(td => {
        const tdTime =td.dataset.time
        if (time >= tdTime && time < this.nextTdTime(td)) td.closest("tr").style.background = 
        `linear-gradient(180deg, rgba(255,255,255,1) ${this.percentOfDay(minute,0)}%, rgba(0,229,255,1) ${this.percentOfDay(minute,20)}%, rgba(245,245,245,1) ${this.percentOfDay(minute,40)}%)`
    });
}

addTrailingZerow(h) {
    if (h.length == 1) return "0" + h
    return h
}
nextTdTime(td){
    const tr = td.closest("tr")
    const nextTr = tr.nextElementSibling
    console.log(nextTr)
    if (nextTr){
        return nextTr.querySelector('.js-time').dataset.time

    }
    return "24:00"
}
percentOfDay(hour,x) {
    console.log(parseInt(hour,10));
    const prec = parseInt(hour,10)/60
    return (prec * 60) + x
    }
}

/*
         {
          
              setInterval(myTimer, 20000);
          
              window.onload = ()=>{
                  console.log("load");
                  myTimer()
                  setWindow()
              }
          
              function setWindow() {
                  document.getElementById('line').scrollIntoView({
                          behavior: 'auto',
                          block: 'center',
                          inline: 'center'
                      });
              }
          
              function myTimer() {
              const mSecondsInDay = 1000*60*60*24
              const d = new Date();
              const mSecondsInPast = new Date(`${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} 00:00`);
              const n = d.getTime();
              const n0 = mSecondsInPast.getTime();
              const parentTablaHeight = document.getElementById("timeTableDiv").scrollHeight
              const HeaderHeight = document.getElementById("timeTableHead").offsetHeight
              const firstRowaHeight = document.getElementById("firstRow").offsetHeight
              const height = parentTablaHeight - firstRowaHeight - HeaderHeight
              const pass = (n - n0) / mSecondsInDay
              const position = height * pass + (firstRowaHeight + HeaderHeight)
              console.log(height , n - n0 , pass)
              document.getElementById("line").style.top = position + "px";
          
              }
          }
          */