import ApplicationController from  '../application_controller'

export default class extends ApplicationController {
    static targets = ['form']
    static values = ['']
  connect(){
    super.connect()
    this.updateInputSize()
this.mutationObserver()
    }

    mutationObserver() {
        // Select the node that will be observed for mutations
const targetNode = this.formTarget

// Options for the observer (which mutations to observe)
const config = { attributes: false, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === 'childList') {
      this.updateInputSize()
    } 
  }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);
    }

    updateInputSize() {
        document.querySelectorAll('input.min-content').forEach(ipt =>{
            const num = ipt.parentElement.className.includes('format') ? 7 : 5;
            ipt.addEventListener('change', function() {
                this.style.width = this.value.length + num + 'ch'
              })
              ipt.style.width = (ipt.value.length + num) + 'ch'
        })
    }
}
