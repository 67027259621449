import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  choosePricingToCompare(e) {
    const checkboxes = [
      ...e.target.closest(".js-btn-group").querySelectorAll("input"),
    ]
      .filter((c) => c.checked)
      .map((c) => parseInt(c.value));
    console.log(checkboxes);
    this.stimulate("compare_pricings#choose_pricing_to_compare", checkboxes);
  }
}
