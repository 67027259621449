import ApplicationController from './application_controller'



export default class extends ApplicationController {
    remove_meal(e) {
        console.log("remove meal");
        let wrapper = e.target.closest(".nested-meals")

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()

            // Existing records are hidden and flagged for deletion
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
}