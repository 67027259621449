import { TabulatorFull as Tabulator } from 'tabulator-tables';
import * as  langs from './langs';
import * as  extensions from './tabulator-extensions';
import * as  columnsBuilder from './columns';
import * as menusBuilder from './menus';

export const  tabelsList = []

export function getTable(id) {
    console.log(id)
   
    if (id instanceof Element || id instanceof HTMLDocument  ) {id = id.querySelector(".tabulator").id}
    id = `#${id}`
    return Tabulator.findTable(id)[0]
}

export function getAllTables() {
    const wrappers = document.querySelectorAll(".table-section.table-wrapper")
    const tables = []
    wrappers.forEach(wrapper => {
        tables.push(Tabulator.findTable(wrapper.querySelector(".tabulator"))[0])
    })
    return tables
}

export function exportAsXlsx(table, fileName) {
    table.download("xlsx", fileName ,{
        documentProcessing:function(workbook){
            //workbook - sheetJS workbook object
  
            //set some properties on the workbook file
            workbook.Props = {
                Title: "ייצוא מאפליקציית הגבעה",
                Subject: "נתוני הגבעה",
                Views: [{RTL:true}]
            };
            return workbook;
        }
    }); //dow
}


export function creatTabolatorTable(tableWrapper, that, ajaxURL, ajaxParams = {} ,options = {}) {
    if (tabelsList.findIndex(table => table.tableId == tableWrapper.id) > 0) return
    extensions.extendTabulator()
    const defaultOptions = {
        persistence:true,
        pagination:true, //enable pagination
        paginationMode:"remote",
        dataSendParams:{
            "size":"per_page", //change page request parameter to "pageNo"
        } ,
        index:"space_id",
        paginationSize:20, // this option can take any positive integer value
        paginationSizeSelector:[10, 20, true], //enable page size select element with these options
        ajaxURL: ajaxURL, //ajax URL
        ajaxParams: ajaxParams, //set any standard parameters to pass with the request
        height:"100%",
        placeholder:"לא מצאנו כלום", //display message to user on empty table
        textDirection:"rtl", // force table into right to left text direction
        layout:"fitColumns",
        groupClosedShowCalcs:true, //show column calculations when a group is closed
        responsiveLayout:"collapse",
        selectable:true,
        locale:"heb",
        clipboard: 'copy',
        movableColumns: true, //enable user movable columns
        langs: langs.getLang(),
        printAsHtml:true,
        printHeader:function(){
            const headers =  document.querySelectorAll(".print-header")
            const mainHeader = headers[0].cloneNode(true)
            mainHeader.style.position = "relative"
            const tableHeader = headers[1].cloneNode(true)
            tableHeader.style.marginTop  = "1rem"
            const div = document.createElement('div')
            div.appendChild(mainHeader)
            div.appendChild(tableHeader)
            return div
        },
        columns: columnsBuilder.getColumns({}),
        ajaxResponse: async function(url, params, response){
            //url - the URL of the request
            //params - the parameters passed with the request
            //response - the JSON object returned in the body of the response.
            const col = tableWrapper.closest('div.table-section').querySelector('div.js-col-list').textContent
            const config = tableWrapper.closest('div.table-section').querySelector('div.js-config-table').textContent
            const response1 = await fetch('/table_settings?table_id=' + tableWrapper.id);
            const columnLayout = await response1.json()
            const combineSettings = mergeObjects(JSON.parse(columnLayout),columnsBuilder.getColumns(response.columns,JSON.parse(col),JSON.parse(config)))
            console.log(tableWrapper.id);
            table.setColumns(combineSettings)
            table.options.rowContextMenu = menusBuilder.getMenus(response.columns)
            console.log(table.options);
            return table.options.paginationMode == "remote" ? response : response.data; //return the response data to tabulator
            },

            
    } 

    for (const [key, value] of Object.entries(options)) {
        
        if (value == "remove") {
            console.log(key,value);
            delete defaultOptions[key]
            delete options[key]
        }
      }

      const temp = defaultOptions.ajaxURL ? tableWrapper : tableWrapper.querySelector('table')
        const table = new Tabulator(temp, {...defaultOptions,...options} );
        table.on("rowSelectionChanged", function(data, rows){
                if (data.length > 0 ) {
                    tableWrapper.closest('div.table-section').querySelector('div.actions').classList.remove('hide'); 
                } else {
                    tableWrapper.closest('div.table-section').querySelector('div.actions').classList.add('hide');
                }
                let text = ""
                data.forEach(e => {
                    text += e[Object.keys(e)[0]] +","
                })
                text = text.slice(0, -1)
                tableWrapper.closest('div.table-section').querySelector('div.js-selected-row-list').textContent = text 

                tableWrapper.closest('div.table-section').querySelector('div.actions span').innerHTML = data.length

          });

          table.on("dataProcessed", function(){ 
            let idsText = tableWrapper.closest('div.table-section').querySelector('div.js-selected-row-list').dataset.spaces
            if (!idsText && idsText == "") {
              idsText ="[]"
            }
            const selectedRoomIds = JSON.parse(idsText).map(function(item) {
                return parseInt(item, 10);
            })            
            if (selectedRoomIds.length > 0) {
                table.selectRow(selectedRoomIds); 
                table.setFilter(table.options.index,"in",selectedRoomIds);
            }
        
            console.log(table.options);
          })


    table.on("cellEdited", function(cell){
        const row = cell.getRow()
        const updateObj = {}
        updateObj[cell.getField()] = cell.getValue()
        row.update(updateObj)
        console.log(cell.getRow().getData()[cell.getField()])


    });
    
    tabelsList.push({table: table, tableId: tableWrapper.id})
    console.log(table.options);
    return table
    }
    

function mergeObjects(arr1 = [{field: ""}], arr2 =  [{field: ""}]) {
    const mergedArr = []
    if (arr1 && arr2) {
        arr1.forEach(obj1 => {
            const obj2 = arr2.find(obj2 => obj2.field == obj1.field)
            mergedArr.push({...obj2,...obj1})
        })
        arr2.forEach(obj2 => {
            const obj1 = mergedArr.find(obj1 => obj1.field == obj2.field)
            obj2.visible = false
            if (!obj1) mergedArr.push(obj2)
        })
    } else return arr2
    return mergedArr
}
