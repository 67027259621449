import consumer from "./consumer"

consumer.subscriptions.create("AppearanceChannel", {
  // Called once when the subscription is created.
  initialized() {
    this.update = this.update.bind(this)
  },

  // Called when the subscription is ready for use on the server.
  connected() {
    console.log("connected");
    this.install()
    this.update()
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    console.log("disconnected");
    this.uninstall()
  },

  // Called when the subscription is rejected by the server.
  rejected() {
    this.uninstall()
  },

  update() {
    document.visibilityState , document.hasFocus() ? this.appear() : this.away()
  },

  appear() {
    // Calls `AppearanceChannel#appear(data)` on the server.
    console.log('appear');
    this.perform("appear", {location: appearingOn()} )
  },
  received(data) {
    console.log("received");
    const userStatusWrapper = document.querySelector("[user-status='"+data.user+"']") 
    if (!userStatusWrapper) return
    const userStatus = userStatusWrapper.querySelector('.status-js')
    const userLoaction = userStatusWrapper.querySelector('.location-js')
    const list = userStatusWrapper.closest('ul')
    userStatusWrapper.className = data.status
    if (userStatus) userStatus.textContent = iconFromStatus(data.status)
    if (userLoaction) userLoaction.textContent = hebLocations(data.location)
    if (list) sortList(list)
  },

  away() {
    // Calls `AppearanceChannel#away` on the server.
    console.log('away');
    this.perform("away")
  },

  install() {
    window.addEventListener("focus", this.update)
    window.addEventListener("blur", this.update)
    document.addEventListener("turbolinks:load", this.update)
    document.addEventListener("visibilitychange", this.update)
  },

  uninstall() {
    window.removeEventListener("focus", this.update)
    window.removeEventListener("blur", this.update)
    document.removeEventListener("turbolinks:load", this.update)
    document.removeEventListener("visibilitychange", this.update)
  },

  get documentIsActive() {
    return document.visibilityState === "visible" && document.hasFocus()
  },

  get appearingOn() {
    const element = document.querySelector("[data-appearing-on]")
    return element ? element.getAttribute("data-appearing-on") : null
  }
})

function hebLocations(location) {
  switch (location){
    case "away":
       return "מחובר אבל במשהו אחר"
    case "disappear":
      return "לא מחובר"   

  }
  return location
}

function iconFromStatus(name) {
  switch (name){
    case "disappear":
       return "do_not_disturb_on"
    case "away":
      return "visibility_off"   
    case "appear":
      return "visibility"   

  }
  return location
}

function appearingOn() {
  const element = document.querySelector("[data-appearing-on]")
  return element ? element.getAttribute("data-appearing-on") : null
}

function sortList(ul){
  var new_ul = ul.cloneNode(false);

  // Add all lis to an array
  var lis = [];
  for(var i = ul.childNodes.length; i--;){
      if(ul.childNodes[i].nodeName === 'LI')
          lis.push(ul.childNodes[i]);
  }

  // Sort the lis in descending order
  lis.sort(function(a, b){
     return rankByStatus(b)  -
            rankByStatus(a);
  });
  // Add them into the ul in order
  for(var i = 0; i < lis.length; i++)
      new_ul.appendChild(lis[i]);
  ul.parentNode.replaceChild(new_ul, ul);
}

function rankByStatus(li) {
  const status = li.nodeName === 'LI' ? li.querySelector('div').className : 0
  switch (status){
    case "disappear":
       return 0
    case "away":
      return 1   
    case "appear":
      return 2   

  }
  return 0
}