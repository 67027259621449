import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "start", 'end' ]
  connect(){
   
  }
  
  setCookie(e) {
    console.log('cookie')
    document.cookie = "meals_start_date=" + this.startTarget.value + ";path=/";
    document.cookie = "meals_end_date=" + this.endTarget.value + ";path=/";
  }


  
}