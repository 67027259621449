import ApplicationController from  '../application_controller'

export default class extends ApplicationController {
    static targets = ["icon","link","text"]
    static values = ["MAX_TIME","time_to_check"]
    connect () {
      this.MAX_TIMEValue = 150000
      this.time_to_checkValue = 5000
      if (this.hasIconTarget && this.hasTextTarget && this.hasLinkTarget)  {
        if (this.linkTarget.dataset.type == "internet") {
          this.setConnectionStatus(this.checkInternetStatus())
          window.addEventListener('online', () => this.setConnectionStatus(this.checkInternetStatus()));
          window.addEventListener('offline', () => this.setConnectionStatus(this.checkInternetStatus()));
      
        }
        else {
          window.addEventListener("load", async (event) => {
            this.setConnectionStatus(await this.checkOnlineStatus())
        })
        setInterval(async () => {
            this.setConnectionStatus(await this.checkOnlineStatus())
            if (this.time_to_checkValue < this.MAX_TIMEValue) this.time_to_checkValue = this.time_to_checkValue  + this.time_to_checkValue
        },this.time_to_checkValue)
        }
      }
        

        
    }
    
    

    checkOnlineStatus = async () => {
        try {
          const online = await fetch(this.linkTarget.dataset.link, {cache: "reload"});
          return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
          return false; // definitely offline
        }
      };

      checkInternetStatus() {
        return  window.navigator.onLine
      };
    


    setConnectionStatus(status) {
        if (this.hasIconTarget && this.hasTextTarget && this.hasLinkTarget) 
        {
            this.linkTarget.classList.toggle("warning", !status)
            this.iconTarget.classList.toggle("spin", !status)
            this.iconTarget.textContent = status ? "public" : "sync";
            this.textTarget.textContent =  status ? "מחובר" : "מתחבר" ;
        }
        
    }
  }
    

  