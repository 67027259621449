import ApplicationController from './application_controller'
import * as tableModule from '../packs/tabulator/tabulator';

export default class extends ApplicationController {
    static values = {
        id: String
      }
    connect(){
        super.connect()
        const that = this
        
        const tableWrapper = document.getElementById(`${this.idValue}_wrapper`)
        console.log(this.idValue);
        if (tableWrapper) {
            that.table = tableModule.creatTabolatorTable(tableWrapper,this, '',{},{
            paginationMode: "local",
            paginationSize:50,
            selectable:1,
            ajaxURL: 'remove', 
            ajaxParams: 'remove',
            ajaxResponse: 'remove',
            columns: 'remove',
            height:"100%",
            textDirection:"rtl", // force table into right to left text direction
            layout:"fitColumns",
            groupClosedShowCalcs:true, //show column calculations when a group is closed
            responsiveLayout:"hide", // hide rows that no longer fit
            cellHozAlign:"right", //center align cell contents
            headerHozAlign:"center", //right align column header titles
            headerSortTristate:true, //enable tristate header sort for all columns
            pagination:"local", //enable local pagination.
            paginationSize:25, // this option can take any positive integer value
            paginationSizeSelector:[10, 25, 50, 100], //enable page size select element with these options
            persistence:true, //enable persistence
            persistenceID: `${this.idValue}_wrapper`
            })
            console.log(that.table);
        }
        
        
    }

    exportToXlsx(e) {
        const name = `${e.currentTarget.dataset.file_name}.xlsx` || "data"
        const table = tableModule.getTable(this.idValue)
        tableModule.exportAsXlsx(table, name);
    }

    printTable(e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(this.idValue)
        console.log("print", table)
        table.print("active",true)
      } 
    
      saveLayout(e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(this.idValue)
        console.log(table.getColumnLayout());
        console.log(JSON.stringify(table.getColumnLayout()));
        this.stimulate("settings#save_table_layout",wrapper.id+"_wrapper", JSON.stringify(table.getColumnLayout())).then(()=> {table.setData()})
      } 
    
      defaultLayout(e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(this.idValue)
        console.log(table.getColumnLayout());
        this.stimulate("settings#delete_table_layout", wrapper.id+"_wrapper").then(()=> {table.setData()})
      } 
    
      updateData(e) {
        const table = tableModule.getTable(this.idValue)
        table.setData()
      }
}