import ApplicationController from  '../application_controller'

export default class extends ApplicationController {
    static targets = [
      'tabsView',
      'tabsWrapper',
      'tabsContainer',
      'tabHeader',
      'pinTabsContainer',
      'pinTabsHeader',
      'pinTabsWrapper']
    static values = ['allTabs','allButtons','tabView']
  connect(){
    super.connect()    
    this.tabViewValue = new TabsView(this.tabsViewTarget)
  
  }

  
}

class TabsView {
  constructor(element) {
    this.element = element
    this.btns =  this.htmlClectionToArray(element.querySelector('.tabsWrapper > header').children)
    this.tabs = this.htmlClectionToArray(element.querySelector('.tabsWrapper > .tabs').children)
    this.currentBtn = this.btns[0]
    this.currentTab = this.tabs[0]
    this.chooseTab(this.currentBtn)
    this.setEventListener()
  }

  hideAll(){
    this.tabs.forEach(tab => {
      tab.classList.add("hide")
    });
    this.btns.forEach(button => {
      button.classList.remove("current")
    });
  }
  chooseTab(btn){
    this.hideAll()
    this.setCurrentBtn(btn)
    this.currentBtn = btn
    this.currentTab = this.element.querySelector(`#${btn.dataset.tab}`)
    this.showTab(this.currentTab)
  }
  showTab(tab){
    tab.classList.remove("hide")
  }
  setCurrentBtn(btn) {
    btn.classList.add("current")
  }
  moveTab(tab,btn, from, to) {
    from.removeChild(tab)
    to.appendChild(tab) 
    from.closest('.wrapper').querySelector(':scope > .header').removeChild(btn)
    to.closest('.wrapper').querySelector(':scope > .header').appendChild(btn)

  }
  show_tab_custom_buttons(current_tab) {
    const btns_to_hide = this.element.querySelectorAll('.custom_buttons');
    const current_tab_btns = this.element.querySelectorAll(`.${current_tab}_only`);
    for(var i = 0; i < btns_to_hide.length; i++){
        btns_to_hide[i].style.visibility = "hidden";
        btns_to_hide[i].style.display = "none"; 
    }
    for(var i = 0; i < current_tab_btns.length; i++){
        current_tab_btns[i].style.visibility = "unset";
        current_tab_btns[i].style.display = "block"; 
    }

  }
  setEventListener(){
    const that = this
      //this.setCurrentTabs()
      this.btns.forEach(btn => {
        btn.addEventListener('click', (event) => {
          event.preventDefault();
          const current_tab = event.target.closest('.tabBtn').getAttribute('data-tab');
          this.show_tab_custom_buttons(current_tab);
          this.chooseTab(event.target.closest('.tabBtn'))
        }) 
        btn.addEventListener('dblclick', (event) => {
          event.preventDefault();
          this.pinTab(event.target.closest('.tabBtn'))
        })
      })

      var element = this.element.querySelector(':scope > .pinTabsWrapper')
      //create box in bottom-left
      var resizer = document.createElement('div');
      resizer.className="border"
      //Append Child to Element
      element.appendChild(resizer);
      //box function onmousemove
      resizer.addEventListener('mousedown', initResize, false);

      //this.element funtion mousemove & mouseup
      function initResize(e) {
        e.preventDefault()
        that.element.addEventListener('mousemove', Resize, false);
        that.element.addEventListener('mouseup', stopResize, false);
      }
      //resize the element
      function Resize(e) {
        element.style.width = e.clientX - element.offsetLeft + 'px';
      }
      //on mouseup remove this.element functions mousemove & mouseup
      function stopResize(e) {
        that.element.removeEventListener('mousemove', Resize, false);
        that.element.removeEventListener('mouseup', stopResize, false);
      }
  }
  pinTab(btn) {
    this.currentBtn = btn
    this.currentTab = this.element.querySelector(`#${btn.dataset.tab}`)
    const pinC = this.element.querySelector(':scope > .pinTabsWrapper > .pinTabs')
    const tabC = this.element.querySelector(':scope > .tabsWrapper > .tabs')
    const tabH = this.element.querySelector(':scope > .tabsWrapper > .tabHeader')
    if (this.currentBtn.parentElement == tabH) {
      this.moveTab(this.currentTab,this.currentBtn,tabC,pinC)
      this.tabs = this.htmlClectionToArray(this.element.querySelector('.tabsWrapper > .tabs').children)
      this.btns =  this.htmlClectionToArray(this.element.querySelector('.tabsWrapper > header').children)
      this.chooseTab(this.btns[0])
   
    } else {
      this.moveTab(this.currentTab,this.currentBtn,pinC,tabC)
      this.tabs = this.htmlClectionToArray(this.element.querySelector('.tabsWrapper > .tabs').children)
      this.btns =  this.htmlClectionToArray(this.element.querySelector('.tabsWrapper > header').children)
      this.chooseTab(this.currentBtn)
      if (pinC.children.length == 0) this.element.querySelector(':scope > .pinTabsWrapper').style.width = "unset"
   }
   }

  htmlClectionToArray(collection) {
   return Array.prototype.slice.call(collection)
  }
}



