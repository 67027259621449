import ApplicationController from './application_controller'
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import * as tableModule from '../packs/tabulator/tabulator'
import { Controller } from "stimulus"
import { event } from 'jquery';


export default class extends ApplicationController {
  static targets = [ 'date', 'nextDays','kind','user','spaces' ]

  get utilController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector(".table-section.table-wrapper"), "util")
  }
  connect(){
    super.connect()
    
    const date = this.hasDateTarget ? this.dateTarget.value : null

    console.log(date);
    this.loadTable( "rooms_vacancies_history_wrapper","/vacancies_data",{kind: "rooms"})
    this.loadTable( "all_cleaning_wrapper","/vacancies_data",{kind: "cleaning"})
    this.loadTable( "all_problems_wrapper","/vacancies_data",{kind: "problems"})
    this.loadTable( "open_problems_wrapper","/vacancies_data",{kind: "problems",filter_column: "end_date", filter_by: "null"})
    this.loadTable( "all_vacancies_wrapper","/vacancies_data",{kind: "not_ours"})
    this.loadTable( "ordered_cleaning_wrapper","/vacancies_data",{kind: "cleaning",filter_column: "expected_date", filter_by: date})
    this.loadTable( "all_rooms_wrapper","/spaces_data",{kind: "rooms",all_rooms: true}, {paginationMode: "local"})
    this.loadTable( "all_rooms_chunks_wrapper","/spaces_data",{kind: "rooms",start_date: date,all_rooms: false})
    this.loadTable( "all_rooms_selected_wrapper","/spaces_data",{kind: "rooms",all_rooms: true}, {paginationMode: "local"})

    
    
    }


    loadTable(wrapperId,url,params) {
      const tableWrapper = document.getElementById(wrapperId)
      if (tableWrapper) {
        const tab = tableModule.creatTabolatorTable(tableWrapper,this, url,params, [])
      }


    }

    saveLayout(e) {
      const wrapper = e.target.closest(".table-section.table-wrapper")
      const table = tableModule.getTable(wrapper)
      console.log(table.getColumnLayout());
      console.log(JSON.stringify(table.getColumnLayout()));
      this.stimulate("settings#save_table_layout",wrapper.id+"_wrapper", JSON.stringify(table.getColumnLayout())).then(()=> {table.setData()})
    } 

    defaultLayout(e) {
      const wrapper = e.target.closest(".table-section.table-wrapper")
      const table = tableModule.getTable(wrapper)
      console.log(table.getColumnLayout());
      this.stimulate("settings#delete_table_layout", wrapper.id+"_wrapper").then(()=> {table.setData()})
    } 

    updateData(e) {
      const wrapper = e.target.closest(".table-section.table-wrapper")
      const table = tableModule.getTable(wrapper)
      table.setData()
    }
  
    clearTableFilter(table) {
      table.clearFilter();

    }

    check_col_def(){

      console.log(Tabulator.findTable(`#all_cleaning_wrapper`)[0]);

    }
  
  
  
  setEndDates(e) {
    const date = this.hasDateTarget ? this.dateTarget.value : null
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    const ids = table.getSelectedData().map(e => parseInt(e[Object.keys(e)[0]], 10)); // find table object for table with id of example-table
    console.log(ids)
    this.stimulate("Vacancies#vacancy_end_date", ids,date).then(() =>   {
      table.setData();
      confetti()
    })
  }

    
  setEndDate(e) {
    const date = this.hasDateTarget ? this.dateTarget.value : null
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    const ids = [e.target.dataset.vacancy || e.target.parentElement.dataset.vacancy]
    console.log(ids,e.target);
    console.log(ids)
    this.stimulate("Vacancies#vacancy_end_date", ids,date).then(() =>   {
      table.setData();
      confetti()
    })
  }

  deleteVacancies(e) {
    const isDate = false
    const date = isDate ? this.dateTarget.value : null
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    const ids = table.getSelectedData().map(e => parseInt(e[Object.keys(e)[0]], 10)); // find table object for table with id of example-table
    this.stimulate("Vacancies#vacancy_delete", ids,date).then(() => {
      table.setData();
    })
  }

  async updateColumn(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log(table.getColumnDefinitions())
    const ids = table.getSelectedData().map(row => row.vacancy_id)
    const columns = table.getColumnDefinitions().filter(col => col.isEditable)
    console.log(columns);
    const col = await this.utilController.openSelectModal(e,columns)
      console.log(col)
    if (!col) return
    const column = columns.filter(column => column.field == col)[0]
    const model = column.model[0]
    const colName = col.substring(`${model}_`.length)
    console.log(colName)
    const options = column.enumTypes.map(e => {
      let rObj = {}
      rObj.field = e
      rObj.title = e
      return rObj
   })
    console.log(column.dataType,column.enumTypes,options)
      const value = await this.utilController.openInputModal(e,column.dataType,options)
      console.log(value)
      if (!value) return
      this.stimulate("Vacancies#vacancy_update", ids,colName,model,value).then(() => {
        table.setData();
      })
  }

  toggleMenu(e) {
    const id = `menu-${e.target.closest("div.table-wrapper").id}`
    console.log(id)
    const menu = document.getElementById(id)
    menu.classList.toggle("show")
  }

  toggleColumn(e) {
    const id = `#id-${e.target.closest("div.table-wrapper").id}`
    console.log(id)
    const table = Tabulator.findTable(id)[0]
    table.toggleColumn(e.target.dataset['id'])
  }

  exportToXlsx(e) {
    const name = `${e.target.dataset.file_name}.xlsx` || "data"
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    tableModule.exportAsXlsx(table, name);
  }

  printTable(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log("print", table)
    table.print("active",true)
  } 

  toggleFilters(e) {
    const state = e.target.dataset['state'] == 'true' ? 'false' : 'true'
    e.target.dataset['state'] = state
    const id = `#id-${e.target.closest("div.table-wrapper").id}`
    const table = Tabulator.findTable(id)[0]; // find table object for table with id of example-table
    const ColumnDefinitions = table.getColumnDefinitions()
    console.log (ColumnDefinitions)

    const updated = []
    ColumnDefinitions.forEach(col => {
      if (col.field ){
        col.headerFilter = state == 'true';
        if ( state == 'true' && (col.field == "סטטוס" || col.field == "סוג" || col.field == "משתמש" || col.field == "בשימוש?" || col.field == "תחזוקה" || col.field == "ניקיון" || col.field == "זמינות")) {
          col.headerFilter = "select"
          col.headerFilterParams = {values:true}
        }
      }
      updated.push(col)
    })
    table.setColumns(updated)

  }

  remove_irelevant_cleaning(e) {
    e.preventDefault();
    const ok = confirm('המשך יסמן תאריך ניקיון לכל חדר שמוגדר נכון לתאריך הנוכחי כמלוכלך יותר מפעם אחת');
    if (ok) {
      this.stimulate("Vacancies#clear_irelevant")
    } else {
      console.log('nothing')
    }
  }

  change_current_vacancies(e) {
    const date = this.dateTarget.value
    const nextDays = this.nextDaysTarget.value
    const kind = this.kindTarget.value
    console.log(kind,nextDays, date)
    this.stimulate("vacancies#change_current_vacancies", kind, nextDays, date).then(() => {
    
    })
    
  }


  
  change_rooms_to_clean_status_date(e) {
    const date = this.dateTarget.value
    this.stimulate("vacancies#set_order_date", date)
  }

  choose_rooms_to_clean(e) {
    const id = `#id-${e.target.closest("div.table-wrapper").id}`
    const table = Tabulator.findTable(id)[0]; // find table object for table with id of example-table
    const data = table.getSelectedData();
    const date = this.dateTarget.value
    const user = this.userTarget.dataset.userid
    const ids = data.map(e => e.room)
    const notes = []
    data.forEach(row => {
      console.log({num_in_room: row["מספר_אורחים"], sheets: this.checkIsTrue(row["מצעים"]), towels: this.checkIsTrue(row["מגבות"]), note: row["הערות"]})
      notes.push( {num_in_room: row["מספר_אורחים"], sheets: this.checkIsTrue(row["מצעים"]), towels: this.checkIsTrue(row["מגבות"]), note: row["הערות"]})
    });
    console.log(date,ids, notes, user)
    this.stimulate("vacancies#set_order_expected_date", date, ids, notes, user).then(() => {
    })
  }

  checkIsTrue(obj){
    if (obj == 'true' || obj == "True") return true
    if (obj === true ) return true
    return false
  }

  update_rooms_notes(e) {
    const id = `#id-${e.target.closest("div.table-wrapper").id}`
    const table = Tabulator.findTable(id)[0]; // find table object for table with id of example-table
    const rows = table.getSelectedRows();
    
    const numInRoom = document.getElementById('numInRoom').value
    const sheets = document.getElementById('sheets').checked
    const towels = document.getElementById('towels').checked
    const notes = document.getElementById('notes').value

    console.log(rows)
    rows.forEach(row => {
      row.update({"מספר_אורחים":numInRoom,
      "מצעים":sheets,
      "מגבות":towels,
      "הערות":notes,
      "notes": JSON.stringify({"num_in_room":numInRoom,"sheets":sheets,"towels":towels,"notes":notes})
      }); //update the row data for field "name"
    })
    console.log(numInRoom)
  }

  open_space_problem_modal(e) {
    const date = this.dateTarget.value
    const box = e.target.closest(".space-box")
    const space_id =   box.dataset['space']
    const kind =   box.dataset['kind']
    document.querySelector('#modal').classList.remove('hide')
    console.log(e.target,box,space_id,kind)
    this.stimulate("vacancies#open_space_problem_modal", space_id, kind, date).then(() => {
    })
  }

  on_row_change(e) {
    const input = e.target
    const type = input.dataset["type"]
    const row = input.closest('tr')
    const note = row.querySelector('td.notes')
    const data = JSON.parse(note.textContent)
    let value = input.value
    console.log(value)
    value == "ckb" ? value = input.checked : value = value
    data[type] = value
    note.innerHTML = JSON.stringify(data)
  }

  set_date(e) {
    const new_date = e.target.dataset["date"]
    this.set_url("start_date", new_date)
    this.dateTarget.value = new_date
    this.loadTable( "all_rooms_wrapper","/spaces_data",{kind: "rooms",date: new_date})

    this.stimulate("vacancies#set_order_date_from_list", new_date).then(() => {
    })
  }

  set_order_date(e) {
    const date = this.dateTarget.value
    this.set_url("start_date",date)
    this.stimulate("vacancies#set_order_date", date).then(() => {
    })
  }

  set_url(param, date) {
    const url = new URL(window.location.href);
		console.log(url)
		url.searchParams.set(param, date)
		window.location.href = url
  }

  change_url(e) {
    const value = e.target.value
    const param = e.target.dataset.param
    this.set_url(param, value)
  }

  columns() {

    return [
            {
                field:"id",
                title:"id",
                visible:false
            }, //add input editor to the name column
            {
                field:"space_name", 
                type: "spaceLink"
            }, //add header filters to the age column
            {
              field:"start_date", 
              type: "date"
          }, //add header filters to the age column
          {
            field:"end_date", 
            type: "date"
        }, //add header filters to the age column
        {
          field:"expected_date", 
          type: "date"
      }, //add header filters to the age column
            {
                field:"kind", 
                visible:false, 
            }, //add header filters to the age column
            {
                field:"title", 
            }, //add input editor to the name column

            {
                field:"notes", 
                download:true, 

            }, //add header filters to the age column
        ]
}
  
}