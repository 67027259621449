export function getMenus(columns ){
    const menu = []
    let first = true
    for (let key in columns) {
        const col = columns[key]
        if (col.field == "id" ){
            if (first) {
                menu.push(getMenuItem(key, `עריכת ${col.model[2]}`,'/' + col.model[1] + '/', "/edit"))
                menu.push({separator:true})
            }
            menu.push(getMenuItem(key, `פרטי ${col.model[2]}`,'/' + col.model[1] + '/'))
            menu.push({separator:true})
            first = false
        }
    }
    return menu
}

function getMenuItem(key, label, urlStart, urlEnd = "") {
    return {
        label: label ,
        action:function(e, row){
            window.open( urlStart + row.getData()[key] + urlEnd, '_blank').focus(); 
        }
    }
}