import ApplicationController from "./application_controller";
import { DirectUpload } from "@rails/activestorage";
import * as tableModule from "../packs/tabulator/tabulator";

import $ from "jquery";

export default class extends ApplicationController {
  connect() {
    super.connect();
    console.count("connect");
  }
  print(e, table = false) {
    console.log("step", e);
    const save = e.currentTarget.dataset.filetype;

    console.count("step");
    const notprint = document.querySelectorAll(".no-print");
    notprint.forEach((el) =>
      el.setAttribute("data-html2canvas-ignore", "true")
    );
    const modalWrapper = this.getModalWrapper(e);
    console.count("step");

    const styleChange = this.styleChanges;
    const uploadFile = this.fileUpload;
    const html2pdfModule = require("html2pdf.js");
    const html2pdf = html2pdfModule;
    console.count("step");

    const opt = {
      margin: 1,
      filename: e.target.dataset.filename,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1, onclone: styleChange },
    };
    console.count("step");

    document.body.appendChild(modalWrapper);

    const body = table ? table : document.body.querySelector("article");
    console.count("step");
    console.log(body);
    // New Promise-based usage:
    const doc = html2pdf()
      .set(opt)
      .from(body)
      .outputPdf("blob", opt)
      .then((result) => {
        console.log(result);
        document
          .getElementById("main-iframe")
          .setAttribute("src", URL.createObjectURL(result));
        console.log(result);
        document.getElementById("savePrint").addEventListener("click", (e) => {
          console.log("save");
          const filename = e.currentTarget.dataset.filename;
          // This fires after the blob has been read/loaded.
          document.getElementById("saved").innerHTML =
            "<i class='material-icons spin'>sync</i>";
          var file = new File([result], filename);
          console.log(e.currentTarget.dataset);
          uploadFile(file, filename, save);
        });
      });
    console.count("step");
  }

  quikPrint() {
    window.print();
  }
  save(e) {}
  close(e) {
    document.querySelector("#previewModal").remove();
  }
  getModalWrapper(e) {
    const modalWrapper = document.createElement("div");
    modalWrapper.style =
      "position: fixed;width: 100vw;height: 100vh;top: 0;left: 0;z-index: 100000;backdrop-filter: brightness(0.5);";
    modalWrapper.id = "previewModal";
    modalWrapper.setAttribute("data-controller", "print");
    modalWrapper.innerHTML =
      `
  <div id="modal" style="position: absolute;top: 50%;left: 50%;z-index: 100;transform: translate(-50%, -50%);width: 80%;height: 80%;padding:1rem;    background: white;">
  <div style="display: flex;width: 100%;justify-content: space-between;">
    <h2>תצוגה מקדימה</h2>
    <div class="flex" style="align-self: center;">
      
      <button id="savePrint" data-filename="` +
      e.currentTarget.dataset.filename +
      `" class="btn mr-1"><div id="saved">שמור</div></button>
      <button class="btn btn-secondary mr-1" data-action="print#close">X</button>
    </div>
  </div>
  <div id="preview" style="overflow: auto;height: inherit;">
  <iframe id="main-iframe" style="width: 100%; height: 100%;z-index: 2; border: none;"></iframe>
  </div>
  <div>
  </div>
</div>
`;
    return modalWrapper;
  }

  get styleChanges() {
    return function dosomthing(documentClone) {
      const headers = document.querySelectorAll(".only-print.print-header");
      const headerCopy = [];
      headers.forEach((h) => {
        const header = h.cloneNode(true);
        header.style.display = "flex";
        header.style.justifyContent = "space-between";
        header.style.margin = "1rem";
        headerCopy.push(header);
      });

      documentClone.querySelectorAll("article").forEach((ar) => {
        console.log(ar);
        const style = ar.querySelector("style");
        const headerWrapper = document.createElement("div");

        headerCopy.forEach((h) => {
          headerWrapper.appendChild(h);
          console.log(headerWrapper);
        });
        console.log();
        ar.insertBefore(headerWrapper, style.nextElementSibling);
      });

      documentClone.querySelectorAll("td, tr, th, a").forEach((el) => {
        if (el) {
          el.style.background = "white";
          el.style.borderRight = "0";
        }
      });

      documentClone.querySelectorAll("a").forEach((el) => {
        if (el) {
          el.style.border = "0";
        }
      });

      documentClone.querySelectorAll("tr.borderSum ").forEach((el) => {
        const prev = el.previousElementSibling;
        if (el && prev && prev.className.includes("borderItem")) {
          prev.querySelectorAll("td").forEach((el) => {
            if (el) {
              el.style.borderBottom = "2px solid black";
            }
          });
        }
      });
      console.log(documentClone);
    };
  }

  get fileUpload() {
    return (file, n, save) => {
      // your form needs the file_field direct_upload: true, which
      //  provides data-direct-upload-url
      const url = "/rails/active_storage/direct_uploads";
      const upload = new DirectUpload(file, url);

      upload.create((error, blob) => {
        if (error) {
          // Handle the error
          document.getElementById("saved").textContent = "תקלה בהעלאת הקובץ";
        } else {
          // Add an appropriately-named hidden input to the form with a
          //  value of blob.signed_id so that the blob ids will be
          //  transmitted in the normal upload flow
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          console.log(save);
          this.stimulate("Print#save", n, hiddenField.value, save);
        }
      });
    };
  }

  tabulator_and_save(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper");
    const table = tableModule.getTable(wrapper);
    console.log("print", table);
    var htmlTable = table.getHtml("visible", true, { columnGroups: false }); //get styled html for visible rows of table that copies the table style without grouped column headers  }
    console.log(htmlTable);
    const tableWrapper = document.createElement("div");
    tableWrapper.innerHTML =
      "<article style='display:flex;flex-direction: column;'><style></style>" +
      htmlTable.trim() +
      "</article>";
    this.print(e, tableWrapper);
  }

  tabulator(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper");
    const table = tableModule.getTable(wrapper);
    console.log("print", table);
    table.print(e.currentTarget.filename, true);
  }
}
