import ApplicationController from './application_controller'
import * as tableModule from '../packs/tabulator/tabulator';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

export default class extends ApplicationController {

    get vacanciesController() {
        return this.application.getControllerForElementAndIdentifier(document.querySelector(".js-room-order"), "vacancies")
      }
      get utilController() {
        return this.application.getControllerForElementAndIdentifier(document.querySelector(".table-section.table-wrapper"), "util")
      }
    connect () {
        super.connect()
    }

    toggle(e) {
        console.log("toggle");
        const el = document.querySelector(`#${e.target.dataset.id}`)
        el.classList.toggle('hide')
    }

    select_tab(e) {
       const tabName = e.target.dataset.tab 
       const buttons = document.querySelectorAll(".tab-button")
       buttons.forEach(button => {
           button.classList.remove("btn-secondary")
           button.classList.add("btn-flat")
       })
       e.target.classList.add("btn-secondary") 
       e.target.classList.remove("btn-flat") 
       const tabs = document.querySelectorAll(".tab")
       tabs.forEach(tab => {
           if (tab.classList.contains(tabName)) {
               tab.classList.remove("hide")
                const table = tableModule.getTable(tab)
                table.redraw(true)
            }
           if (!tab.classList.contains(tabName)) tab.classList.add("hide")
       })
    }
    async add_cleaning_order (e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(wrapper)
        const data = table.getSelectedData();
        const date = this.vacanciesController.dateTarget.value
        const ids = data.map(e => e.space_id)
        console.log('/vacancies_data?kind=cleaning&filter_column=expected_date&filter_by='+date);
        const response = await fetch('/vacancies_data?kind=cleaning&filter_column=expected_date&filter_by='+date)
        console.log(response);
        const currentVacancies = await response.json()
        console.log(currentVacancies);
        const currentIds = currentVacancies.data?.map(e => e.space_id);
        console.log(currentIds)
        const alreadySelectedIds = ids.filter(e => currentIds.includes(e) )
        const alreadySelectedRoomsNames = alreadySelectedIds.map(e =>  currentVacancies.data?.find(v => v.space_id == e).space_name)
        if (alreadySelectedRoomsNames.length > 0 && !window.confirm("לחדרים " + alreadySelectedRoomsNames + " כבר הוזמן ניקיון בתאריך: " + date + " האם להזמין ניקיון נוסף?")) return
        const notes = []
        data.forEach(row => {
            console.log(row);
          console.log({num_in_room: row["number_custom"], sheets: row["sheets_custom"], towels: row["towels_custom"], note: row["notes_custom"]})
          notes.push( {num_in_room: row["number_custom"], sheets: row["sheets_custom"], towels: row["towels_custom"], note: row["notes_custom"]})
        });
        console.log(date,ids, notes)
        this.stimulate("vacancies#set_order_expected_date", date, ids, notes).then(() => {
        console.log("success")          
        const el = document.getElementById(`new-order-room-cleaning-modal`)
        el.classList.add('hide')
        tableModule.getTable("ordered_cleaning_wrapper").setData()
        })
    }
    async edit_vacancy_notes  (e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(wrapper)
        const columns = table.getColumnDefinitions().filter(col => col.isEditable)
        const col = await this.utilController.openSelectModal(e,columns)
        const date = this.vacanciesController.dateTarget.value
        const data = table.getSelectedData();
        console.log(data);

        data.sort(function (a, b) {
            return a.vacancy_id - b.vacancy_id;
          });
        console.log(data)
        const vacancy_ids = data.map(e => e.vacancy_id)
        console.log( vacancy_ids)
        vacancy_ids.sort()
        console.log( vacancy_ids)

        if (!col) return
        const column = columns.filter(column => column.field == col)[0]
        let dataType = column.dataType
        if (dataType == "sheets" || dataType == "towels") dataType = "checkbox"
        if (dataType == "notes") dataType = "input"
        let value = await this.utilController.openInputModal(e, dataType, [])
        console.log(value)
        if (!value) return
        if (value == "on") value = true
        if (value == "off") value = false
        const model = "vacancy"
        const colName = col.substring(`${model}_`.length)
        if ("sheets notes towels number".includes(col)) {
            const notes = []
            data.forEach(row => {
                row[col] = value
                console.log({num_in_room: row["number"], sheets: this.checkIsTrue(row["sheets"]), towels: this.checkIsTrue(row["towels"]), note: row["notes"]})
                notes.push( {id: row["vacancy_id"], num_in_room: row["number"], sheets: this.checkIsTrue(row["sheets"]), towels: this.checkIsTrue(row["towels"]), note: row["notes"]})
            });

            this.stimulate("vacancies#update_order_expected_date", date, vacancy_ids, notes).then(() => {
                table.setData();
            })
        } else {
            console.log("Vacancies#vacancy_update", vacancy_ids,colName,model,value);
            this.stimulate("Vacancies#vacancy_update", vacancy_ids,colName,model,value).then(() => {
                table.setData();
            })
        }

    }
    async edit_space_notes  (e) {
        const wrapper = e.target.closest(".table-section.table-wrapper")
        const table = tableModule.getTable(wrapper)
        const columns = table.getColumnDefinitions().filter(col => col.isEditable)
        const col = await this.utilController.openSelectModal(e,columns)
        if (!col) return
        const column = columns.filter(column => column.field == col)[0]
        const options = column.enumTypes.map(e => {
            let rObj = {}
            rObj.field = e
            rObj.title = e
            return rObj
         })
        console.log(column);
        let value = await this.utilController.openInputModal(e,column.dataType,options)
        console.log(value)
        if (!value) return
    
        const selectedRows = table.getSelectedRows()

        if (value == "on") value = true
        if (value == "off") value = false
        
        selectedRows.forEach(row => {
            const updateObj = row.getData()
            updateObj[column.field] = value
            console.log(updateObj);
            row.update(updateObj)
        })

    }
    checkIsTrue(obj){
        if (obj == 'true' || obj == "True" || obj == "כן") return true
        if (obj === true ) return true
        if (obj == "לא" || obj == "") return false
        return false
      }

getData(e){
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log(tableModule.getAllTables());
    console.log(table.getColumnDefinitions());
}

}