// import ApplicationController from './application_controller.js'
import * as tableModule from '../packs/tabulator/tabulator'
import ApplicationController from './application_controller'



export default class extends ApplicationController {
  static targets = [ 'addName', 'nameTemplate', 'addContact', 'contactTemplate' ]
  connect(){
    super.connect()
    const tableWrapper = document.getElementById("all_customers_wrapper")
    console.log(tableWrapper);
    if (tableWrapper) {
      const table = tableModule.creatTabolatorTable(tableWrapper,this, '/customers_data',{},{paginationMode: "local",paginationSize:10,selectable:1})
      table.on("rowClick", function(e, row){
        //e - the click event object
        //row - row component
        console.log(e)
        console.log(row)
        window.open('/customers/' + row.getData().customer_id, '_blank').focus(); 
      });
    }

  }
 add_name(e){
   const content = this.nameTemplateTarget.innerHTML
   this.addNameTarget.insertAdjacentHTML('beforebegin', content)
 }

 add_contact(e){
   const content = this.contactTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
   this.addContactTarget.insertAdjacentHTML('beforebegin', content)
   const box = document.querySelector(".event-customers-contacts")
   if (box) {box.classList.add('box')}
 }

 remove_contact(e) {

  let wrapper = e.target.closest(".nested-contacts")
  const box = document.querySelector(".event-customers-contacts")
  // New records are simply removed from the page
  if (wrapper.dataset.newRecord == "true") {
    wrapper.remove()

  // Existing records are hidden and flagged for deletion
  } else {
    wrapper.querySelector("input[name*='_destroy']").value = 1
    wrapper.style.display = 'none'
    
  }

  if (box && box.querySelectorAll(".nested-contacts").length == 0 ){box.classList.remove('box')}

 }

  exportToXlsx(e) {
    const name = `${e.target.dataset.file_name}.xlsx` || "data"
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    tableModule.exportAsXlsx(table, name);
  }

  printTable(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log("print", table)
    table.print("active",true)
  } 

  saveLayout(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log(table.getColumnLayout());
    console.log(JSON.stringify(table.getColumnLayout()));
    this.stimulate("settings#save_table_layout",wrapper.id+"_wrapper", JSON.stringify(table.getColumnLayout())).then(()=> {table.setData()})
  } 

  defaultLayout(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    console.log(table.getColumnLayout());
    this.stimulate("settings#delete_table_layout", wrapper.id+"_wrapper").then(()=> {table.setData()})
  } 

  updateData(e) {
    const wrapper = e.target.closest(".table-section.table-wrapper")
    const table = tableModule.getTable(wrapper)
    table.setData()
  }


}
