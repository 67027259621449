import { map } from "jquery";

export default function ContextMenu(that, pts) {
  const contextMenu = getContextMenu();
  const scope = document.getElementById("spaces-table-wrapper");
  const body = document.documentElement;
  const normalizePozition = (mouseX, mouseY) => {
    // ? compute what is the mouse position relative to the container element (scope)
    let { left: scopeOffsetX, top: scopeOffsetY } =
      scope.getBoundingClientRect();

    scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX;
    scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY;

    const scopeX = mouseX - scopeOffsetX;
    const scopeY = mouseY - scopeOffsetY;

    // ? check if the element will go out of bounds
    const outOfBoundsOnX = scopeX + contextMenu.clientWidth > scope.clientWidth;

    const outOfBoundsOnY =
      scopeY + contextMenu.clientHeight > scope.clientHeight;

    let normalizedX = mouseX;
    let normalizedY = mouseY;

    // ? normalize on X
    if (outOfBoundsOnX) {
      normalizedX = scopeOffsetX + scope.clientWidth - contextMenu.clientWidth;
    }

    // ? normalize on Y
    if (outOfBoundsOnY) {
      normalizedY =
        scopeOffsetY + scope.clientHeight - contextMenu.clientHeight;
    }

    return { normalizedX, normalizedY };
  };

  scope.addEventListener("contextmenu", (event) => {
    event.preventDefault();

    const tds = [...document.querySelectorAll(".selected-td")];
    if (tds.length > 1) {
      const groupByKey = (tds, dataset, key) =>
        tds.reduce(
          (hash, obj) => ({
            ...hash,
            [obj[dataset][key]]: (hash[obj[dataset][key]] || []).concat(obj),
          }),
          {}
        );
      const tdGrouped = groupByKey(tds, "dataset", "space");

      const data = Object.entries(tdGrouped).map((value) =>
        value[1].map((v) => Object.assign({}, v.dataset))
      );
      const sortedData = data.map((space) =>
        space.sort((a, b) => a.date.localeCompare(b.date))
      );

      const groupedDates = sortedData.map((space) =>
        get_start_end_of_consecutive_dates(
          space.map((sd) => new Date(sd.date)),
          [...new Set(space.map((sp) => sp.space))]
        ).flat()
      );
      const spaceIds = data
        .map((value) => [...new Set(value.map((v) => JSON.parse(v.space)))])
        .flat();
      const chunksIds = [
        ...new Set(
          data
            .map((value) =>
              value.map((v) => (v.chunk ? JSON.parse(v.chunk) : null))
            )
            .flat(2)
        ),
      ];
      const vacIds = [
        ...new Set(
          data
            .map((value) =>
              value.map((v) => (v.vac ? JSON.parse(v.vac) : null))
            )
            .flat(2)
        ),
      ];

      console.log(vacIds);
      function get_start_end_of_consecutive_dates(dates, space) {
        const spaceId = JSON.parse(space[0]);
        const newDate = [];
        let startDate, lastDate;
        dates.forEach(function (date) {
          //check if first date
          if (date == dates[0]) {
            startDate = date;
          }
          //check if cosecutive else push dates
          if (!isConsecutive(lastDate, date) && date !== dates[0]) {
            newDate.push([startDate, lastDate, spaceId]);
            startDate = date;
          }
          //check if last and push
          if (date == dates[dates.length - 1]) {
            newDate.push([startDate, date, spaceId]);
          }
          // set last date to current
          lastDate = date;
        });
        return newDate;
      }

      function isConsecutive(lastDate, date) {
        const lastResult = new Date(lastDate);
        const result = new Date(date);
        lastResult.setDate(lastResult.getDate() + 1);
        return lastResult.toString() == result.toString();
      }
      console.log(groupedDates, spaceIds);

      that
        .stimulate(
          "Spaces#change_context_menu",
          spaceIds,
          groupedDates,
          chunksIds,
          vacIds,
          true
        )
        .then((e) => {
          doStuff(event);
        });
    } else {
      const td = event.target.closest("td") || event.target.closest("th");
      that
        .stimulate(
          "Spaces#change_context_menu",
          td.dataset.space,
          td.dataset.date,
          td.dataset.chunk,
          td.dataset.vac,
          false
        )
        .then((e) => {
          doStuff(event);
        });
    }
  });

  body.addEventListener("click", (e) => {
    // ? close the menu if the user clicks outside of it
    const contextMenu = getContextMenu();
    if (e.target.offsetParent != contextMenu) {
      contextMenu.classList.remove("open");
    }
  });

  function doStuff(event) {
    const { clientX: mouseX, clientY: mouseY } = event;
    const contextMenu = getContextMenu();
    const { normalizedX, normalizedY } = normalizePozition(mouseX, mouseY);

    contextMenu.classList.remove("open");

    contextMenu.style.top = `${normalizedY}px`;
    contextMenu.style.left = `${normalizedX}px`;

    setTimeout(() => {
      contextMenu.classList.add("open");
    });
  }

  function getContextMenu() {
    return document.getElementById("context-menu");
  }
}
