import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { Module } from 'tabulator-tables';

class MoreColumnsOptionsModule extends Module{
    constructor(table){
        super(table);
            //register column definition options
        this.registerColumnOption("enumTypes", []);
        this.registerColumnOption("dataType", "string");
        this.registerColumnOption("model", "");
        this.registerColumnOption("isEditable", false);
    }

    initialize(){
        //called by the table when it is ready for module integrations
    }
}

MoreColumnsOptionsModule.moduleName = "moreColumnsOptionsModule";
Tabulator.registerModule(MoreColumnsOptionsModule);

export function extendTabulator(){
    Tabulator.extendModule("accessor", "accessors", {
        dateAccessor:function(value, data, type, params, column){
            return new Date(value).toLocaleDateString();
        },
        htmlAccessor: function(value, data, type, params, column){
            var s = value;
            var htmlObject = document.createElement('div');
            htmlObject.innerHTML = s;
            const a = htmlObject.firstChild
            const text = a ? a.textContent : ""
            return text;
        },
        spaceName:function(value, data, accessorParams){
            const valuesA = value.split(",");
            const nameA = valuesA[1] || ""
            console.log(nameA,data)
            return nameA;
        },
        statusName:function(value, data, accessorParams){
            const template = document.createElement('template');
            const html = value.trim(); // Never return a text node of whitespace as the result
            template.innerHTML = html;
            
            return template.content.firstChild.textContent;
        }
      
    })

    Tabulator.extendModule("mutator", "mutators", {
        //define custom mutator
        customMutator: function(value, data, type, params, component){
            return data.name; //return the new value for the cell data.
        },
        dateMutator: function(value, data, type, params, component){
            var newDateOptions = {year: "numeric",month: "2-digit",day: "2-digit"}
            const date = value ? new Date(value) : null
            return date ? date.toLocaleString("en-GB", newDateOptions) : ""; //return the new value for the cell data.
        },
        //define custom mutator
        statusMutator: function(value, data, type, params, component){
            const statuses = {cancelled: "בוטל", ended: "הסתיים", ordered: "הוזמן", proposal: "הצעת מחיר", payed: "שולם",zikuy: "זוכה",reciept: "חשבונית", created: "נוצר"}
            return statuses[data.status]; //return the new value for the cell data.
        },
        //define custom mutator
        vacancyMutator: function(value, data, type, params, component){

            const iconDone = "<span class='material-icons'>done</span>"
            const isLong = new Date().setDate(new Date().getDate() + 7) < new Date(value) ? "dirty_alt" : "dirty"
            const iconToClean = "<span class='material-icons'>" + isLong + "</span>"
            return new Date() < new Date(value) ? iconDone :  iconToClean //return the new value for the cell data.
        },
        vacancyNotesMutator: function(value, data, type, params, component){
            if (data.vacancy_notes) {
                const dataArr = data.vacancy_notes.split(',', 4)
                if (dataArr.length == 4) {
                    console.log(dataArr[3],dataArr[3].split(':').shift(),dataArr[3].split(':',2));
                    const notes = {
                        number: parseInt(dataArr[0].split(':', 2)[1],10) || dataArr[0].split(':', 2)[1] ,
                        sheets: dataArr[1].includes("כן") ? "כן" : "לא",
                        towels: dataArr[2].includes("כן") ? "כן" : "לא",
                        notes: dataArr[3].substring("vgru,: ".length,dataArr[3].length)
                    }
                    return notes[params] //return the new value for the cell data.
                } else {
                    return params == "notes" ? data.vacancy_notes : ""
                }  
            }
            return value //return the new value for the cell data.
            
        },
        //define custom mutator
        customerNameMutator: function(value, data, type, params, component){
            let name = data.customer_name
            if (data.customer_parent_id && data.customer_parent_id != "") {name = name + " שייך ללקוח אחר"}
            return name; //return the new value for the cell data.
        },
        isChosen:function(value, data, type, mutatorParams){
            return mutatorParams[0] //return the new value for the cell data.
        },
        log:function(value, data, type, mutatorParams){
            console.log(data)
            console.log(value)
            console.log(type)
            return value
        },
        houseName:function(value, data, type, mutatorParams){
            return value.name
        },
        firstEventName:function(value, data, type, mutatorParams){
            let name = ""
            try {name = data.space_events[0].name} catch(err){}
            return name
        },
        isChunkIncludes:function(value, data, type, mutatorParams){
            if (data[`${mutatorParams}_custom`]) return data[`${mutatorParams}_custom`]
            let chunk = {}
            const event = data.space_events[0]
            chunk = event?.chunks?.filter(c => c.space_id == data.space_id)[0]
            if (chunk) return chunk[mutatorParams] 
            return value
        },
    })
    Tabulator.extendModule("columnCalcs", "calculations", {
        "trueCalc":function(values, data, calcParams){
            //values - array of column values
            //data - all table data
            //calcParams - params passed from the column definition object
    
            var calc = 0;
    
            values.forEach(function(value){
            if(value ==  true || value == "true"){
            calc ++;
            }
            });
    
            return  "true: " + calc ;
        }
    });

    Tabulator.extendModule("format", "formatters", {

        printFormatter:function(cell, formatterParams, onRendered){
            var s = cell.getValue();
            var htmlObject = document.createElement('div');
            htmlObject.innerHTML = s;
            const a = htmlObject.firstChild
            const text = a ? a.textContent : ""
              return text;
          },
          statusFormatter: function(cell, formatterParams){
            const statuses = {cancelled: "בוטל", ended: "הסתיים", ordered: "הוזמן", proposal: "הצעת מחיר", payed: "שולם",zikuy: "זוכה",reciept: "חשבונית", created: "נוצר"}
            return `<div style="height: 100%;
            text-align: center;
            line-height: 40px;
            font-size: 1rem;" class='${Object.keys(statuses).find(key => statuses[key] === cell.getValue())}-bg'>${cell.getValue()}</div>`
         },
         printIcon:function(cell, formatterParams){
            return "<span class='material-icons'>"+ cell.getValue() + "</span>";
        },
        actions:function(cell, formatterParams){
            return cell.getValue();
        },
        space:function(cell,formatterParams){
            const values = cell.getValue().split(",");
            const id = values[0] || 0
            const name = values[1] || ""
            return "<a href='/spaces/"+ id + "' class='link-to-view'>" + name + "<span class='material-icons'>wysiwyg</span></a>";
        },
        firstEventNameFormatter:function(cell,formatterParams){
            return cell.getValue() ? cell.getValue() : "-"
        },
        yesNoVacancyFormatter:function(cell,formatterParams){
            if (cell.getValue() == "כן") return '<svg enable-background="new 0 0 24 24" height="14" width="14" viewBox="0 0 24 24" xml:space="preserve"><path fill="#2DC214" clip-rule="evenodd" d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z" fill-rule="evenodd"></path></svg>'
            if (cell.getValue() == "לא") return '<svg enable-background="new 0 0 24 24" height="14" width="14" viewBox="0 0 24 24" xml:space="preserve"><path fill="#CE1515" d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z"></path></svg>'

            return cell.getValue() ? cell.getValue() : ""
        },
        
        spaceName:function(cell, accessorParams){
            return cell.getValue()
    
        },
        textIcon:function(cell,formatterParams){
            
            switch (cell.getValue()) {
                case 'נקי':
                case 'תקין':
                case 'זמין':
                case 'פנוי':
                    return "<span class='material-icons'>check</span>";
                case 'מלוכלך':
                    return "<span class='material-icons'>dirty</span>";
                case 'מקולקל':
                    return "<span class='material-icons'>fix_alt</span>";
                case 'לא זמין':
                    return "<span class='material-icons'>block</span>";
                default:
                    return cell.getValue();
              }
              
            
        }
    })

    Tabulator.extendModule("filter", "filters", {
        //custom max min filter function
        minMaxFilterFunction:function(headerValue, rowValue, rowData, filterParams){
            //headerValue - the value of the header filter element
            //rowValue - the value of the column in this row
            //rowData - the data for the row being filtered
            //filterParams - params object passed to the headerFilterFuncParams property
                if(rowValue){
                    if(headerValue.start != ""){
                        if(headerValue.end != ""){
                            return rowValue >= headerValue.start && rowValue <= headerValue.end;
                        }else{
                            return rowValue >= headerValue.start;
                        }
                    }else{
                        if(headerValue.end != ""){
                            return rowValue <= headerValue.end;
                        }
                    }
                }
        
            return true; //must return a boolean, true if it passes the filter.
            }
    })

    Tabulator.extendModule("edit", "editors", {
        minMaxFilterEditor: function(cell, onRendered, success, cancel, editorParams){
            var end;
            var container = document.createElement("span");
            //create and style inputs
            var start = document.createElement("input");
            start.setAttribute("type", "date");
            start.setAttribute("placeholder", "Min");
            start.style.padding = "4px";
            start.style.width = "50%";
            start.style.boxSizing = "border-box";
            start.value = cell.getValue();
        
            function buildValues(){
                success({
                    start:start.value,
                    end:end.value,
                });
            }
    
            function keypress(e){
                if(e.keyCode == 13){
                    buildValues();
                }
        
                if(e.keyCode == 27){
                    cancel();
                }
            }
            end = start.cloneNode();
            end.setAttribute("placeholder", "Max");
        
            start.addEventListener("change", buildValues);
            start.addEventListener("blur", buildValues);
            start.addEventListener("keydown", keypress);
        
            end.addEventListener("change", buildValues);
            end.addEventListener("blur", buildValues);
            end.addEventListener("keydown", keypress);
        
        
            container.appendChild(start);
            container.appendChild(end);
        
            return container;
        },
        checkBoxEditor:function(cell, onRendered, success, cancel){
            //cell - the cell component for the editable cell
            //onRendered - function to call when the editor has been rendered
            //success - function to call to pass the successfuly updated value to Tabulator
            //cancel - function to call to abort the edit and return to a normal cell
        
            //create and style input
            var cellValue = cell.getValue()
            const input = document.createElement("input");
        
            input.setAttribute("type", "checkbox");
        
            input.style.padding = "3px";
            input.style.width = "50%";
            input.style.height = "50%";
            input.style.margin = "5px";
            input.style.boxSizing = "border-box";
        
            input.checked = cellValue;
        
            onRendered(function(){
                input.focus();
            });
        
            function onChange(){
                console.log(input.checked, cellValue)
                if(input.checked != cellValue){
                    success(input.checked);
                    console.log(cell);
                }else{
                    cancel();
                }
            }
        
            //submit new value on blur or change
            input.addEventListener("blur", onChange);
        
            //submit new value on enter
            input.addEventListener("keydown", function(e){
                if(e.keyCode == 13){
                    onChange();
                }
        
                if(e.keyCode == 27){
                    cancel();
                }
            });
        
            return input;
        }

        
    });
        


        Tabulator.extendModule("columnCalcs", "calculations", {
            "trueCalc":function(values, data, calcParams){
                //values - array of column values
                //data - all table data
                //calcParams - params passed from the column definition object
        
                var calc = 0;
        
                values.forEach(function(value){
                if(value ==  true || value == "true"){
                calc ++;
                }
                });
        
                return  "true: " + calc ;
            }
        });
        
 
        Tabulator.extendModule("sort", "sorters", {
            rowSelectionSort:function(a, b, aRow, bRow, column, dir, params){
                return aRow.isSelected() ? -1 : 1 
            },
            spaceSort:function(a, b, aRow, bRow, column, dir, params){
                const valuesA = a.split(",");
                const nameA = valuesA[1] || ""
                const valuesB = b.split(",");
                const nameB = valuesB[1] || ""
                return nameA.localeCompare(nameB)
            },
        });
}


export function HeaderMenu() {
    var menu = [];
    const columns = this.getColumns()
    for(let column of columns){
        //create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("material-icons");
        icon.textContent = column.isVisible() ? "check_box" : "check_box_outline_blank";

        //build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        //create menu item
        menu.push({
            label:label,
            action:function(e){
                //prevent menu closing
                e.stopPropagation();
    
                //toggle current column visibility
                column.updateDefinition({visible: !column.isVisible() }).then(function(column){
                    if(column.isVisible()){
                        icon.textContent = "check_box"
                        
                    }else{
                        icon.textContent = "check_box_outline_blank"
                    }
                })
                .catch(function(error){
                    console.log(error)
                });
                //change menu item icon
                
            }
        });
    }
    console.log(menu)
   return menu;
};
