import ApplicationController from './application_controller'
import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';


export default class extends ApplicationController {
  static targets = [ 'contacts' ]
  connect() {
    super.connect()
function formatState (state) {
    if (!state.id) {
      return state.text;
    }
    var $state = $(
      '<span><i class="material-icons">' + state.element.value.toLowerCase() + '</i> ' + state.text.replace(/_/g, " ") + '</span>'
    );
    return $state;
  };
  
  $(".icon-select").select2({
    templateResult: formatState
  
  });
}
}