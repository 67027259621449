import ApplicationController from './application_controller'
import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';


export default class extends ApplicationController {
  static targets = [ 'contacts' ]
  connect() {
    super.connect()
    $('.select2').select2({
      theme: 'bootstrap4',
  });
    $(".select2-customers").on('select2:select', e => {
      console.log("list item selected");
      if (this.hasContactsTarget) {
        this.choose_customer(e)
      }
    })

    $(".select2-events").on('select2:select', e => {
      console.log("list item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.stimulate("RoomOrder#show", e.target.value)
    });

  }

  choose_customer(e){
    console.log(e.target.value);
    this.stimulate("Events#choose_customer", e.target.value)

  }
  finalizeReflex(){
    $('#event_contact_ids').select2()
  }
}
