import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  export(e) {
    const event_id = e.target.dataset.event;
    const file_id = e.target.dataset.file;
    const meal_ids = this.getIds("meals");
    const room_ids = this.getIds("rooms");
    const classroom_ids = this.getIds("classrooms");
    const order_ids = this.getIds("room_orders");

    console.log(event_id, file_id, meal_ids);
    const is_new = confirm(
      "האם לשמור את המסמך בהערה חדשה? (אם לא התכנה תחפש קובץ שיוצא ותדרוס אותו)"
    );
    this.stimulate(
      "Export#export",
      event_id,
      is_new,
      file_id,
      meal_ids,
      room_ids,
      classroom_ids,
      order_ids
    );
  }

  getIds(tableId) {
    const table = this.getTable(tableId);
    const arr = [...table.querySelectorAll("tr")];
    const ids = [];
    arr.forEach((tr) => {
      if (tr.dataset.recordid) ids.push(tr.dataset.recordid);
    });
    return ids;
  }
  getTable(id) {
    return document.getElementById(id).querySelector("table");
  }
}
