import ApplicationController from "./application_controller";
import { Controller } from "stimulus";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as langs from "../packs/tabulator/langs";
import * as extensions from "../packs/tabulator/tabulator-extensions";

export default class extends Controller {
  get utilController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("#account-wrapper"),
      "util"
    );
  }

  connect() {
    super.connect();
    // add your code here, if applicable
    //custom max min header filter
    const that = this;

    extensions.extendTabulator();

    this.loadPage(that);
  }

  exportData(e) {
    const table = Tabulator.findTable("#accounting-table")[0];
    table.download("xlsx", "data.xlsx", { sheetName: "My Data" });
  }

  loadPage(that) {
    //define table
    const table = new Tabulator("#accounting-table", {
      ajaxURL: "/events_data", //ajax URL=
      height: "100%",
      textDirection: "rtl", // force table into right to left text direction
      layout: "fitColumns",
      selectable: 1,
      responsiveLayout: "hide", // hide rows that no longer fit
      pagination: "local", //enable local pagination.
      paginationSize: 25, // this status-btn can take any positive integer value
      paginationSizeSelector: [10, 25, 50, 100], //enable page size select element with these options
      columns: this.columns(),
      persistence: {
        sort: true, //persist column sorting
        filter: true, //persist filter sorting
        group: true, //persist row grouping
        page: true, //persist page
        columns: false, //persist columns
      }, //enable table persistence
      persistenceID: "example1", //id string, can only be numbers, letters, hyphens and underscores.
      locale: "heb",
      langs: langs.getLang(),
    });
    table.options.rowClickMenu = this.menu(that, table);
  }

  columns() {
    return [
      {
        field: "id",
        headerFilter: true,
        visible: false,
      }, //add input editor to the name column
      {
        field: "event",
        visible: true,
        sorter: "string",
        headerFilter: true,
        bottomCalc: "count",
        accessorDownload: "htmlAccessor",
        formatterPrint: "printFormatter",
        mutator: "customMutator",
      }, //add header filters to the age column
      {
        field: "status",
        visible: true,
        sorter: "string",
        mutator: "statusMutator",
        headerFilterPlaceholder: " ",
        headerFilter: "list",
        headerFilterFunc: "in",
        headerFilterParams: {
          valuesLookup: "active",
          valuesLookupField: "status", //the field to lookup values from
          clearable: true, //show clear "x" button on editpr
          itemFormatter(label, value, item, element) {
            //label - the text lable for the item
            //value - the value for the item
            //item - the original value object for the item
            //element - the DOM element for the item
            console.log(element);

            console.log(item, "item");
            return "<strong>" + label + " </strong>";
          },
          sort: "asc", //sort direction for the values list
          multiselect: true,
        },
        formatter: "statusFormatter",
        accessorDownload: "htmlAccessor",
        formatterPrint: "printFormatter",
      }, //add input editor to the name column
      {
        field: "customer_name",
        visible: true,
        sorter: "string",
        mutator: "customerNameMutator",
        headerFilter: true,
        formatter: "html",
        formatterPrint: "printFormatter",
        accessorDownload: "htmlAccessor",
      }, //add header filters to the age column
      {
        field: "customer_email",
        visible: true,
        sorter: "string",
        headerFilter: true,
      }, //add input editor to the name column
      {
        field: "start_date",
        visible: true,
        sorter: "string",
        headerFilter: "minMaxFilterEditor",
        accessorDownload: "dateAccessor",
        headerFilterFunc: "minMaxFilterFunction",
        headerFilterLiveFilter: false,
      }, //add header filters to the age column
      {
        field: "end_date",
        visible: false,
        download: true,
        sorter: "string",
        headerFilter: "minMaxFilterEditor",
        accessorDownload: "dateAccessor",
        headerFilterFunc: "minMaxFilterFunction",
        headerFilterLiveFilter: false,
      }, //add header filters to the age column
      {
        field: "final_price",
        visible: true,
        sorter: "number",
        headerFilter: true,
        bottomCalc: "sum",
        bottomCalcParams: {
          precision: 1,
        },
        formatter: "money",
        formatterParams: {
          decimal: ".",
          thousand: ",",
          symbol: "₪",
          symbolAfter: "₪",
          precision: false,
        },
      }, //add header filters to the age column
    ];
  }

  menu(that, table) {
    console.log(this);
    console.log(table);
    return function (e, row) {
      const rowData = row.getData();
  
      // Check if the row has a customer_id, such as the footer row
      if (!rowData.customer_id) {
        return null; // Prevent menu from opening
      }
    

      return [
        {
          label: "פרטי לקוח",
          action: function (e, row) {
            window
              .open("/customers/" + row.getData().customer_id, "_blank")
              .focus();
          },
        },
        {
          separator: true,
        },
        {
          label: "פרטי אירוע",
          action: function (e, row) {
            window.open("/events/" + row.getData().id, "_blank").focus();
          },
        },
        {
          separator: true,
        },
        {
          label: "שינוי סטטוס", //sub menu nested in sub menu
          menu: [
            {
              label: "נוצר",
              action: function (e, row) {
                e.target.value = "created";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "הצעת מחיר",
              action: function (e, row) {
                e.target.value = "proposal";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "הוזמן",
              action: function (e, row) {
                e.target.value = "ordered";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "הסתיים",
              action: function (e, row) {
                e.target.value = "ended";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "זוכה",
              action: function (e, row) {
                e.target.value = "zikuy";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "חשבונית",
              action: function (e, row) {
                e.target.value = "reciept";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "שולם",
              action: function (e, row) {
                e.target.value = "payed";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
            {
              separator: true,
            },
            {
              label: "בוטל",
              action: function (e, row) {
                e.target.value = "canceled";
                e.target.dataset.event = row.getData().id;
                that.utilController.quick_change_status(e).then((e) => {
                  table.replaceData();
                });
              },
            },
          ],
        },
      ];
    }
  }
}
