const  getLang = 

 function () {
   return {"heb":{
        "columns":{
            "start_date":"תאריך התחלה",
            "end_date":"תאריך סיום",
            "expected_date":"תאריך צפוי",
            "status":"סטטוס",
            "customer_name":"לקוח",
            "customer_email":"דואר אלקטרוני",
            "final_price":"סכום",
            "event":"אירוע",
            "space_name": "מיקום",
            "kind": "סוג",
            "notes": "הערות",
            "title": "כותרת",
            "actions": "פעולות",
            "created_at":"תאריך יצירה",
            "updated_at": "תאריך עדכון",
            "name": "שם",
            "vacancy_title": "כותרת",
            "vacancy_notes": "הערות",
            "vacancy_kind": "סוג מצב חדר",
            "space_house": "שם הבית",
            "bed_num": "מספר מיטות",
            "size": "גודל"
        },
        "ajax":{
            "loading":"טוען",
            "error":"תקלה"
        },
        "groups":{ 
            "item":"item",
            "items":"items"
        },
        "pagination":{
            "page_size":"גודל הדף", 
            "page_title":"הצג",
            "first":"ראשון", 
            "first_title":"העמוד הראשון", 
            "last":"אחרון",
            "last_title":"העמוד האחרון",
            "prev":"קודם",
            "prev_title":"Prev Page",
            "next":"הבא",
            "next_title":"Next Page",
            "all":"הכל"
        },
        "headerFilters":{
            "default":"סנן...",
            "columns":{
                "name":"f.."
            }
        },
        "vacancies": {
            "kindes": {
                "cleaning": "ניקיון"
            }
        }
    }
}
}

export {getLang} 
