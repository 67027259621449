// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = [ 'roomTemplate', 'addRoom', 'mealTemplate', 'addMeal', 'classroomTemplate', 'addClassroom', 'saveMeal' ]

	save_meal(e){
		e.preventDefault();
		const wrapper = e.target.closest("tr");
		const form = wrapper.querySelector("form");
		wrapper.querySelectorAll("td").forEach(td => {
			form.append(td);
		});
		Rails.fire(form, 'submit');
		form.querySelectorAll("td").forEach(td => {
			wrapper.append(td);
		});
		wrapper.querySelector(".btn-secondary").disabled = false;
	  }

	add_room(e){
		var content = this.roomTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
		this.addRoomTarget.insertAdjacentHTML('beforebegin', content)
	  }
	remove_room(e) {
		let wrapper = e.target.closest("tr")
		if (wrapper.dataset.newRecord == "true") {
			wrapper.remove()
		} else {
			wrapper.querySelector("input[name*='_destroy']").value = 1
			wrapper.style.display = 'none'
		}
	}
	validate_rooms(e){
		console.log('validate');
		let wrapper = e.target.closest("tr")
		const start = parseInt(wrapper.querySelector("[data-range='start']").value)
		const end = parseInt(wrapper.querySelector("[data-range='end']").value)
		const error = wrapper.querySelector(".pricing-error")
		const submit = document.querySelector('.form-submit')
		if (start > end && !error.classList.contains('show')) {
			error.classList.add('show')
			submit.disabled = true
		}else if (start <= end && error.classList.contains('show')){
			error.classList.remove('show')
			submit.disabled = false
		}
	}
	add_classroom(e){
		var content = this.classroomTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
		this.addClassroomTarget.insertAdjacentHTML('beforebegin', content)
	  }
	remove_classroom(e) {
		let wrapper = e.target.closest(".classroom-pricing")
		if (wrapper.dataset.newRecord == "true") {
			wrapper.remove()
		} else {
			wrapper.querySelector("input[name*='_destroy']").value = 1
			wrapper.style.display = 'none'
		}
	}
	add_meal(e){
		var content = this.mealTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
		this.addMealTarget.insertAdjacentHTML('beforebegin', content)
	  }
	remove_meal(e) {
		let wrapper = e.target.closest(".meal-pricing")
		wrapper.remove()
	}

	searchClassroom(e) {
		const input = e.target.value
		const rows = document.querySelectorAll('.class-row')
		rows.forEach(row => {
			console.log(row, row.children[0])
			const text = row.children[0].textContent || row.children[0].innerText
			if (text.indexOf(input) > -1) {
				row.style.display = "";
			} else {
				row.style.display = "none";
			}
		})
	}
}

